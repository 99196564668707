import { Layer } from '../layers/layerTypes/layer';
import { IActionConfig } from '../layers/layerTypes/layerFactory';

export abstract class Controller {
    protected layer: Layer;
    protected actions: IActionConfig[] = [];

    public async do(layer: Layer, action: string, ...values: any[]): Promise<void> {
        const actionConfig = this.actions.find((actionConfig) => actionConfig.name === action);

        if (actionConfig === undefined) {
            return;
        }

        await actionConfig.action(layer, ...values);
    }

    public hasAction(action: string): boolean {
        return this.actions.some((actionConfig) => actionConfig.name === action);
    }

    public addAction(action: IActionConfig) {
        this.actions.push(action);
    }

    public onAdd(): void {
        // Implement in subclass
    }

    public setLayer(layer: Layer) {
        this.layer = layer;
    }
}
