import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { type SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';
import { BonusSymbolAnimation } from '../controllers/bonusSymbolAnimation';

export const BONUS_SYMBOL_ANIMATION_LAYER = () =>
    LayerFactory.Create(LayerType.CONTAINER, {
        id: 'bonusSymbolLayer',
        layout: {
            position: {
                x: 0,
                y: 0,
            },
            width: '100%',
            height: '100%',
        },
        controllers: [
            new BonusSymbolAnimation({
                reelSpacing: 375,
                symbolHeight: 400,
                symbolWidth: 375,
                columns: 5,
                rows: 4,
                symbolFactory: () => {
                    const spine = LayerFactory.CreateSpine({
                        spineData: 'Bonus',
                    });

                    return spine;
                },
                playBonusLandAnimation: async (spine: SpineLayer) => {
                    spine.position.x -= 20;
                    await spine.play('animation');
                },
            }),
        ],
    });
