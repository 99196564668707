import DataStore from 'game_libs/dataStore';
import IFreeSpinsProgressEvent from 'game_libs/engineEvents/freeSpinsProgressEvent';
import FreeSpinDataTypes from 'game_libs/freeSpinDataTypes';
import IParser from 'game_libs/parsing/IParser';
import { CUSTOM_DATA_TYPES } from './customDataTypes';

const CUSTOM_FREE_SPINS_PROGRESS_PARSER: IParser = {
    parse: (data: IFreeSpinsProgressEvent) => {
        if (data.context.freeSpinRemaining > 0) {
            const lastCount = DataStore.get<number>(FreeSpinDataTypes.REMAINING);
            const retriggeredFSCount = data.context.freeSpinRemaining - lastCount;

            DataStore.set(CUSTOM_DATA_TYPES.RETRIGGERED_FREESPIN_COUNT, retriggeredFSCount + 1);
        }
    },
};

export default CUSTOM_FREE_SPINS_PROGRESS_PARSER;
