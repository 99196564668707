import { IBigWinConfig } from 'game_libs/slotMachine/features/wins/bigWinFeature';
import DataTypes from 'game_libs/dataTypes';
import DataStore from 'game_libs/dataStore';
import CascadeDataTypes from 'game_libs/parsing/dataTypes/cascadeDataTypes';
import { features } from 'game_libs/controllers/featureController';

export const BIG_WIN_CONFIG: IBigWinConfig = {
    condition: () => {
        const winningsFromReconnection = DataStore.get<boolean>(
            DataTypes.WINNINGS_FROM_RECONNECTION,
        );

        if (winningsFromReconnection) {
            return false;
        }

        if (features.cascade) {
            const accum = DataStore.get<number>(CascadeDataTypes.ACCUMULATED_WINNINGS);

            if (accum >= DataStore.get<number>(DataTypes.TOTAL_BET) * 5) {
                DataStore.set(DataTypes.TOTAL_WIN, accum);

                return true;
            }

            return false;
        }

        const accum = DataStore.get<number>(DataTypes.TOTAL_WIN);

        if (accum >= DataStore.get<number>(DataTypes.TOTAL_BET) * 10) {
            DataStore.set(DataTypes.TOTAL_WIN, accum);

            return true;
        }

        return false;
    },
    levels: [
        {
            multiplier: 10,
            name: 'big',
            time: 4000,
        },
        {
            multiplier: 50,
            name: 'mega',
            time: 4000,
        },
        {
            multiplier: 100,
            name: 'epic',
            time: 5000,
        },
    ],
};
