import DataStore from '../../../dataStore';
import { GameBus } from '../../../gameBus';

import { timeout } from '../../../utils/timeout';
import SlotMachineEntity from '../../slotMachineEntity';
import SlotMachineFeature, { IFeatureConfig } from '../slotMachineFeature';
import { SYMBOL_CONTROLLER_ACTIONS } from '../../../controllers/symbolWinAnimationController';

import { PAYLINES_CONTROLLER_ACTIONS } from '../../../controllers/paylinesController';

import { Layer } from '../../../layers/layerTypes/layer';
import DataTypes from '../../../dataTypes';
import { IWinnings } from '../../../platformInterface';
import FreeSpinDataTypes from '../../../freeSpinDataTypes';

declare const EventManager: any;

export enum SINGLE_WIN_LOOP_ACTIONS {
    SHOW_LINE_WIN = 'SHOW_LINE_WIN',
    HIDE = 'HIDE',
}

export interface ISingleWinLineLoopConfig extends IFeatureConfig {
    time: number;
    showPaylines?: boolean;
    iterations?: number;
    slotMachineEnd?: boolean;
}

export default class SingleWinLineLoopFeature extends SlotMachineFeature {
    private time: number;
    private iterations: number;
    private showPaylines: boolean;
    private ended = false;
    private config: ISingleWinLineLoopConfig;

    constructor(config: ISingleWinLineLoopConfig) {
        super(config);

        this.config = config;

        this.time = config.time;
        this.iterations = config.iterations || Infinity;
        this.showPaylines = config.showPaylines === undefined ? true : config.showPaylines;
    }

    public condition(): boolean {
        return (
            DataStore.get<number>(DataTypes.TOTAL_WIN) > 0 &&
            !DataStore.get(DataTypes.SPIN_IN_PROGRESS) &&
            !DataStore.get<boolean>(FreeSpinDataTypes.IS_NEXT)
        );
    }

    private async end() {
        return new Promise<void>(async (resolve) => {
            GameBus.once('spinStart', () => {
                this.ended = true;
                resolve();
            });
        });
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        if (DataStore.get(DataTypes.SPIN_IN_PROGRESS)) {
            return;
        }
        console.debug('SINGLE WIN LINE LOOP FEATURE');
        this.ended = false;
        EventManager.emit('game:done', {});
        const win = DataStore.get(DataTypes.TOTAL_WIN);
        const winData = DataStore.get<Array<IWinnings>>(DataTypes.WINNINGS);

        EventManager.emit('game:winnings', { amount: win });
        const root = DataStore.get(DataTypes.ROOT_LAYER) as Layer;
        const totalWinLayer = root.findRecursive('totalWinLayer');
        const slotMachineLayer = root.findRecursive('slotMachine');
        const payLinesLayer = root.findRecursive('paylinesLayer');
        const end = this.end();

        if (this.config.slotMachineEnd) {
            GameBus.emit('slotMachineFeaturesEnd');
        }

        for (let j = 0; j < this.iterations; j++) {
            if (this.ended) {
                break;
            }

            for (let i = 0; i < winData.length; i++) {
                const winning = winData[i];

                const p: Promise<void>[] = [];

                if (this.showPaylines && payLinesLayer) {
                    GameBus.emit('showPayline', winning);
                    p.push(
                        payLinesLayer.do(
                            PAYLINES_CONTROLLER_ACTIONS.SHOW_PAYLINE,
                            parseInt(winning.id, 10),
                        ),
                    );
                }

                await Promise.race([
                    end,
                    Promise.all([
                        totalWinLayer.do(
                            SINGLE_WIN_LOOP_ACTIONS.SHOW_LINE_WIN,
                            slotMachine,
                            winning.winnings,
                        ),
                        slotMachineLayer?.do(
                            SYMBOL_CONTROLLER_ACTIONS.ANIMATE_WINNING_LINE,
                            slotMachine,
                            winning,
                        ),
                    ]),
                ]);

                await Promise.race([end, timeout(this.time)]);
                await Promise.race([
                    end,
                    await Promise.all([
                        totalWinLayer.do(SINGLE_WIN_LOOP_ACTIONS.HIDE),
                        this.showPaylines &&
                            payLinesLayer?.do(PAYLINES_CONTROLLER_ACTIONS.HIDE_ALL_PAYLINES),
                    ]),
                ]);
            }
        }
    }
}
