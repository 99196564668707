import EventEmitter from 'events';
import TypedEmitter from 'typed-emitter';
import { timeout } from './timeout';

import { Ticker } from '@pixi/core';

export interface IFromTo {
    x?: number;
    y?: number;
    alpha?: number;
}

interface TweenEvents {
    update: (current: IFromTo | number, time: number) => void;
    finish: () => void;
}

export class Tween {
    private running = false;
    private ticker: Ticker;
    private tweenFrom: IFromTo | number;
    private tweenTo: IFromTo | number;
    private tweenCurrent: IFromTo | number;
    private tweenTime: number;
    private target: any;
    private easing: (t: number) => number;
    private emitter: TypedEmitter<TweenEvents>;
    private startTime: number;
    private promise: Promise<void>;
    private id: string;
    private currentTime: number;

    constructor() {
        this.ticker = Ticker.shared;
        this.ticker.add(this.update, this);

        this.emitter = new EventEmitter() as TypedEmitter<TweenEvents>;
    }

    public setID(id: string) {
        this.id = id;
    }

    public setEasing(easing: (t: number) => number) {
        this.easing = easing;

        return this;
    }

    public connect(target: any): Tween {
        this.target = target;

        return this;
    }

    public from(from: IFromTo | number): Tween {
        this.tweenFrom = from;

        return this;
    }

    public to(to: IFromTo | number): Tween {
        this.tweenTo = to;

        return this;
    }

    public time(time: number): Tween {
        this.tweenTime = time;

        return this;
    }

    public async start(): Promise<void> {
        if (this.id) {
            console.debug(`STOP ${this.id}`);
        }

        this.running = true;
        this.startTime = this.ticker.lastTime;
        this.promise = timeout(this.tweenTime);
        await this.promise;
    }

    public stop() {
        if (this.id) {
            console.debug(`STOP ${this.id}`);
        }
        this.running = false;
        Promise.resolve(this.promise);
    }

    public onUpdate(callback: (current: IFromTo | number, time: number) => void): Tween {
        this.emitter.on('update', callback);

        return this;
    }

    public onFinish(callback: () => void): Tween {
        this.emitter.on('finish', callback);

        return this;
    }

    private update() {
        if (!this.running) {
            return;
        }
        this.currentTime = this.ticker.lastTime - this.startTime;
        let t = 0;

        if (typeof this.tweenFrom === 'number' && typeof this.tweenTo === 'number') {
            if (this.tweenFrom < this.tweenTo) {
                t = Math.min(this.currentTime / this.tweenTime, 1);
                if (this.easing) {
                    t = this.easing(t);
                }
                this.tweenCurrent = this.tweenFrom + (this.tweenTo - this.tweenFrom) * t;
            } else {
                t = 1 - Math.min(this.currentTime / this.tweenTime, 1);
                if (this.easing) {
                    t = this.easing(t);
                }
                this.tweenCurrent = this.tweenTo + (this.tweenFrom - this.tweenTo) * t;
            }
        } else {
            t = Math.min(this.currentTime / this.tweenTime, 1);
            if (this.easing) {
                t = this.easing(t);
            }
            this.tweenCurrent = {};
            for (const property in this.tweenFrom as any) {
                (this.tweenCurrent as any)[property] =
                    (this.tweenFrom as any)[property] +
                    ((this.tweenTo as any)[property] - (this.tweenFrom as any)[property]) * t;
            }

            for (const property in this.tweenCurrent as any) {
                (this.target as any)[property] = (this.tweenCurrent as any)[property];
            }
        }

        this.emitter.emit('update', this.tweenCurrent, this.currentTime);

        if (this.currentTime >= this.tweenTime) {
            this.emitter.emit('finish');
            this.running = false;
        }
    }
}
