import DataStore from '../../../dataStore';

import { AwaitReply } from '../../../utils/awaitReply';
import SlotMachineEntity from '../../slotMachineEntity';
import SlotMachineFeature, { IFeatureConfig } from '../slotMachineFeature';
import DataTypes from '../../../dataTypes';

declare const EventManager: any;

export interface IBigWinConfig extends IFeatureConfig {
    levels: IBigWinLevel[];
}

interface IBigWinLevel {
    multiplier: number;
    name: string;
    time: number;
}

export default class BigWinFeature extends SlotMachineFeature {
    private config: IBigWinConfig;

    constructor(config: IBigWinConfig) {
        super(config);
        console.debug('BWF');
        this.config = config;
    }

    public condition(): boolean {
        const totalWin = DataStore.get<number>(DataTypes.TOTAL_WIN);
        const totalBet = DataStore.get<number>(DataTypes.TOTAL_BET);
        const isReconnection = DataStore.get<number>(DataTypes.WINNINGS_FROM_RECONNECTION);

        console.log(`BWF TOTAL WIN: ${totalWin}, TOTAL BET: ${totalBet}`);

        return !isReconnection && totalWin >= totalBet * this.config.levels[0].multiplier;
    }

    public async trigger(_slotMachine: SlotMachineEntity): Promise<void> {
        console.debug('Big Win Feature');
        console.debug(
            `WIN: ${DataStore.get(DataTypes.TOTAL_WIN)} THRESHOLD: ${
                DataStore.get<number>(DataTypes.TOTAL_BET) * this.config.levels[0].multiplier
            }`,
        );
        const win = DataStore.get(DataTypes.TOTAL_WIN);

        await AwaitReply('bigWinStart', 'bigWinEnd', this.config);
        EventManager.emit('game:winnings', { amount: win });
        console.debug('Big Win Feature Complete');
    }
}
