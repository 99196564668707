import { Audio } from 'game_libs/utils/audio';
export const AUDIO_CONFIG: Audio.IAudioConfig = {
    events: [
        // {
        //     eventName: "enterMainGame",
        //     soundName: "MusicMainGame",
        //     type: Audio.IAudioEventType.PLAY,
        //     loop: true
        // },
        // {
        //     eventName: "enterFreeSpins",
        //     handler: async () => {
        //         Audio.fade("MusicMainGame", 1, 0, 500);
        //         await Audio.play("MusicFreeSpinsIntro");
        //         Audio.play("MusicFreespins", true);
        //     }
        // },
        // {
        //     eventName: "exitFreeSpins",
        //     handler: async () => {
        //         Audio.stop("MusicFreespins");
        //     }
        // },
        // {
        //     eventName: "resumeFreeSpins",
        //     handler: async () => {
        //         Audio.play("MusicFreespins", true);
        //     }
        // },
        // {
        //     eventName: "anticipationStart",
        //     handler: (reelIndex) => {
        //         Audio.play(`ReelAnticipation${reelIndex - 1}`);
        //     }
        // },
        // {
        //     eventName: "anticipationContinue",
        //     handler: (reelIndex) => {
        //         Audio.play(`ReelAnticipation${reelIndex - 1}`);
        //     }
        // },
        // {
        //     eventName: "showPayline",
        //     soundName: "WinSmall1",
        //     type: Audio.IAudioEventType.PLAY
        // },
        // {
        //     eventName: "showAllPaylines",
        //     soundName: "WinSmall1",
        //     type: Audio.IAudioEventType.PLAY
        // },
        {
            eventName: 'reelStop',
            soundName: 'reelStop',
            type: Audio.IAudioEventType.PLAY,
        },
        // {
        //     eventName: "spinStart",
        //     soundName: "reelSpin",
        //     type: Audio.IAudioEventType.PLAY
        // },
        // {
        //     eventName: "spinStart",
        //     soundName: "BetButton",
        //     type: Audio.IAudioEventType.PLAY
        // },
        // {
        //     eventName: "bigWinStart",
        //     handler: () => {
        //         Audio.fade("MusicMainGame", 1, 0.4, 330);
        //     }
        // },
        // {
        //     eventName: "bigWinEnd",
        //     handler: () => {
        //         Audio.fade("MusicMainGame", 0.4, 1, 330);
        //     }
        // },
        // {
        //     eventName: "bigWinChange",
        //     handler: async (winLevel) => {
        //         console.log(winLevel);
        //         switch(winLevel) {
        //             case 0:
        //                 Audio.play("WinBig");
        //             break;
        //             case 1:
        //                 Audio.play("WinMega");
        //             break;
        //             case 2:
        //                 Audio.play("WinEpic");
        //             break;
        //         }
        //     }
        // }
    ],
};
