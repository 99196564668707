import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { type SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';
import { PlaceWildsController } from 'controllers/placeWildsController';
import { ACTION_ORDER } from 'game_libs/layers/layerTypes/layer';
import { theme } from 'game_libs/controllers/themeController';
import SlotMachineEntity from 'game_libs/slotMachine/slotMachineEntity';

export const PLACED_WILDS_LAYER = () =>
    LayerFactory.Create(LayerType.CONTAINER, {
        id: 'placedWildsLayer',
        actionOrder: ACTION_ORDER.PARALLEL,
        layout: {
            position: {
                x: 10,
                y: 40,
            },
            width: '100%',
            height: '100%',
        },
        layerChildren: [
            theme.placedWilds &&
                LayerFactory.CreateSpine({
                    spineData: 'placedWilds',
                    actions: [
                        {
                            name: 'placedWildsLayer',
                            action: async (layer: SpineLayer) => {
                                // TODO: rename this animation to 'go-wild'
                                await layer.play('turning-red');
                            },
                        },
                    ],
                    layout: theme.placedWilds,
                    start: (scarab: SpineLayer) => {
                        scarab.play('static');
                    },
                }),

            LayerFactory.CreateContainer({
                controllers: [
                    new PlaceWildsController({
                        reelSpacing: 260,
                        symbolHeight: 310,
                        symbolWidth: 260,
                        symbolFactory: (spineData: string) => {
                            const spine = LayerFactory.CreateSpine({ spineData });

                            spine.getSpine().scale.set(2);

                            return spine;
                        },
                        playWildPlaceAnimation: (async (
                            layer: SpineLayer,
                            slotMachine: SlotMachineEntity,
                            spine: SpineLayer,
                            x: number,
                            y: number,
                        ) => {
                            spine.position.set(x, y);
                            spine.play('active');
                        }) as any,
                    }),
                ],
            }),
        ],
    });
