import { Ticker } from '@pixi/core';
/**
 * Returns a promise that will resolve in `milliseconds` milliseconds
 * @param milliseconds
 */
export async function timeout(milliseconds: number): Promise<void> {
    const ticker = Ticker.shared;
    let resolved = false;

    return new Promise((resolve) => {
        if (milliseconds === 0) {
            milliseconds = 1;
        }

        let t = 0;
        const tick = () => {
            t += ticker.elapsedMS;
            if (t >= milliseconds) {
                if (!resolved) {
                    resolve();
                    resolved = true;
                    ticker.remove(tick);
                }
            }
        };

        ticker.add(tick);
    });
}
