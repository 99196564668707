import { ControllerFactory } from 'game_libs/controllers/controllerFactory';
import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { type SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';
import { Audio } from 'game_libs/utils/audio';
import { theme } from 'game_libs/controllers/themeController';
import { Tween } from 'game_libs/utils/tween';

export const ANTICIPATION_LAYER = () => {
    const anticipationLayer = LayerFactory.Create(LayerType.CONTAINER, {
        id: 'anticipationLayer',
        layout: {
            position: { x: 0, y: 0 },
            scale: { x: 1, y: 1 },
            ...theme.anticipation,
        },
        actions: [
            {
                name: 'show',
                action: async (layer: SpineLayer) => {
                    layer.visible = false;
                },
            },
        ],
        layerChildren: [
            LayerFactory.CreateSpine({
                spineData: 'Anticipations',
                controllers: [
                    ControllerFactory.CreateTween({
                        tweens: [
                            {
                                startAction: 'hideAnticipation',
                                from: { alpha: 1 },
                                to: { alpha: 0 },
                                time: 330,
                            },
                        ],
                    }),
                ],
                events: [
                    {
                        event: 'anticipationStart',
                        handler: async (layer: SpineLayer, reelIndex: number) => {
                            anticipationLayer.visible = true;

                            Audio.play('anticipation');

                            anticipationLayer.x =
                                Number(theme.anticipation.position.x) +
                                (reelIndex ?? 0) *
                                    (theme.anticipation.horizontalOffset + theme.reelSpacing);

                            Audio.play(`Anticipation`);

                            await layer.play('start');
                            await layer.play('idle', true);
                        },
                    },
                    {
                        event: 'anticipationContinue',
                        handler: async (layer: SpineLayer, reelIndex: number) => {
                            anticipationLayer.visible = true;

                            Audio.play('anticipation');

                            const newPosition =
                                Number(theme.anticipation.position.x) +
                                (reelIndex ?? 0) *
                                    (theme.anticipation.horizontalOffset + theme.reelSpacing);

                            new Tween()
                                .from(anticipationLayer.x)
                                .to(newPosition)
                                .time(200)
                                .onUpdate((current: number) => {
                                    anticipationLayer.x = current;
                                })
                                .start();

                            Audio.play(`Anticipation`);

                            await layer.play('idle', true);
                        },
                    },
                    {
                        event: 'anticipationStop',
                        handler: async (layer: SpineLayer) => {
                            await layer.play('end');

                            anticipationLayer.visible = false;
                        },
                    },
                ],
            }),
        ],
    });

/////////////////
//////////////////////////////////////////
///////////////////////////////////////////////////////////
/////////////////////////////////////////
///////
//////////////

    return anticipationLayer;
};
