export namespace Locale {
    export let translations: any;

    export async function load(locale: string) {
        const response = await fetch(`locales/${locale}/translations.json`);

        Locale.translations = await response.json();
    }

    export function get(key: string): string {
        return Locale.translations[key];
    }
}
