import DataStore from '../dataStore';
import DataTypes from '../dataTypes';

let dragEl: HTMLDivElement;

function startDragging(dataWindow: HTMLDivElement) {
    dragEl = dataWindow;
    document.addEventListener('pointermove', drag);
}

function drag() {
    const mouseX = DataStore.get(DataTypes.MOUSE_X);
    const mouseY = DataStore.get(DataTypes.MOUSE_Y);

    dragEl.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
}

function buildVal(val: any) {
    const valEl = document.createElement('div');

    if (typeof val === 'object') {
        if (Array.isArray(val)) {
            const list = document.createElement('ul');

            list.style.paddingLeft = '5px';
            val.forEach((v, index) => {
                const item = document.createElement('li');
                const indexEl = document.createElement('span');

                indexEl.innerHTML = index.toString();
                item.appendChild(indexEl);
                const arrayEl = buildVal(v);

                arrayEl.style.paddingLeft = '5px';
                item.appendChild(arrayEl);
                list.appendChild(item);
            });
            valEl.appendChild(list);
        }
    } else {
        valEl.innerText = val;
    }

    return valEl;
}

export namespace DataStoreBrowser {
    let browser: HTMLDivElement;
    let list: HTMLDivElement;
    const windows: HTMLDivElement[] = [];

    export function initialise() {
        browser = document.createElement('div');
        list = document.createElement('div');

        document.getElementById('game-div')?.appendChild(browser);
        browser.appendChild(list);
        browser.style.backgroundColor = 'rgba(0, 0, 0, 0.5';
        browser.style.position = 'absolute';
        browser.style.zIndex = '1';
        browser.style.color = 'white';
        browser.style.textAlign = 'left';
        browser.style.fontFamily = 'monospace';
        browser.style.paddingRight = '20px';

        document.addEventListener('pointerup', () => {
            document.removeEventListener('pointermove', drag);
        });

        DataStore.onAdd(() => {
            updateList();
        });
        updateList();
    }

    export function open(dataRef: string) {
        const dataWindow = document.createElement('div');
        const val = DataStore.get(dataRef);
        const valuesEl = document.createElement('div');

        document.getElementById('game-div')?.appendChild(dataWindow);
        const title = document.createElement('div');

        title.style.cursor = 'move';
        title.addEventListener('pointerdown', () => {
            startDragging(dataWindow);
        });
        title.innerText = dataRef;
        title.style.paddingBottom = '10px';
        dataWindow.appendChild(title);
        dataWindow.appendChild(valuesEl);
        valuesEl.appendChild(buildVal(val));

        dataWindow.style.backgroundColor = 'rgba(0, 0, 0, 0.5';
        dataWindow.style.position = 'absolute';
        dataWindow.style.zIndex = '2';
        dataWindow.style.color = 'white';
        dataWindow.style.textAlign = 'left';
        dataWindow.style.fontFamily = 'monospace';
        dataWindow.style.padding = '20px';
        dataWindow.style.paddingTop = '10px';
        dataWindow.style.transform = `translate(${window.innerWidth / 2 + windows.length * 20}px, ${
            window.innerHeight / 2 + windows.length * 20
        }px)`;
        windows.push(dataWindow);
        DataStore.onSet(dataRef, () => {
            valuesEl.innerHTML = '';
            const val = DataStore.get(dataRef);

            valuesEl.appendChild(buildVal(val));
        });
    }

    export function updateList() {
        list.innerHTML = '';
        const dataRefs = DataStore.list();
        const listUL = document.createElement('ul');

        dataRefs.forEach((dataRef) => {
            const refTitle = document.createElement('li');

            refTitle.innerText = dataRef;
            refTitle.style.cursor = 'pointer';
            refTitle.onclick = () => {
                open(dataRef);
            };
            listUL.appendChild(refTitle);
        });
        list.appendChild(listUL);
    }
}
