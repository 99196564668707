import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import IConfigEvent from '../engineEvents/configEvent';
import IParser from './IParser';

const DEFAULT_STATE = 'MAIN';

let reelOffset = 0;

export interface IConfigParserConfiguration {
    reelOffset: number;
}

const CONFIG_PARSER: IParser = {
    configure: (configuration: IConfigParserConfiguration) => {
        reelOffset = configuration.reelOffset;
    },

    parse: (data: IConfigEvent) => {
        const taxonomy = data.panelConfigurationPerState[DEFAULT_STATE].taxonomy.slice(reelOffset);
        const paylines = data.panelConfigurationPerState[DEFAULT_STATE].paylines;
        const prizeTable = data.panelConfigurationPerState[DEFAULT_STATE].prizeTable.map;
        const prizeTableScaler = data.panelConfigurationPerState[DEFAULT_STATE].prizeTableScaler;

        DataStore.set(DataTypes.TAXONOMY, taxonomy);
        DataStore.set(DataTypes.PAYLINES, paylines);
        DataStore.set(DataTypes.PRIZE_TABLE, prizeTable);
        DataStore.set(DataTypes.PRIZE_TABLE_SCALER, prizeTableScaler);
    },
};

export default CONFIG_PARSER;
