export const symbolIDs = [
    'H1',
    'H2',
    'H3',
    'H4',
    'M1',
    'M2',
    'M3',
    'M4',
    'M5',
    'L1',
    'L2',
    'L3',
    'L4',
    'L5',
    'SC',
    'WI',
    'WI_EX',
];
