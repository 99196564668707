import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import { ICoord } from '../platformInterface';
import { GameBus } from '../gameBus';
import ISpinEvent from '../engineEvents/spinEvent';
import IParser from './IParser';
import SlotDataTypes from './dataTypes/slotDataTypes';

export interface ISpinParserConfiguration {
    reelOffset: number;
}

let reelOffset = 0;

const SPIN_PARSER: IParser = {
    configure(configuration: ISpinParserConfiguration) {
        reelOffset = configuration.reelOffset;
    },

    parse: (data: ISpinEvent) => {
        const panel: Array<Array<string>> = [];

        DataStore.set(DataTypes.WINNINGS_FROM_RECONNECTION, false);
        for (let i = reelOffset; i < data.panel.reels.length; i++) {
            panel.push(data.basePanel.reels[i].symbols);
        }
        DataStore.clear(SlotDataTypes.SYMBOL_TRANSITIONS);
        DataStore.clear(SlotDataTypes.EXPANDING_SYMBOLS);
        DataStore.clear(SlotDataTypes.OVERRIDE_SYMBOLS);
        DataStore.clear(SlotDataTypes.NEW_PANEL);
        DataStore.set(DataTypes.TOTAL_WIN, data.winningsAmount);
        DataStore.set(DataTypes.PREVIOUS_PANEL, DataStore.get(DataTypes.PANEL));
        DataStore.set(DataTypes.PANEL, panel);
        DataStore.set(DataTypes.CAN_STOP, true);
        DataStore.set(DataTypes.TOTAL_BET, data.betAmount);

        const symbolSpots: Map<string, ICoord[]> = new Map();

        for (let x = 0; x < panel.length; x++) {
            for (let y = 0; y < panel[x].length; y++) {
                const symbol = panel[x][y];

                if (!symbolSpots.has(symbol)) {
                    symbolSpots.set(symbol, [{ reel: x, row: y }]);
                } else {
                    symbolSpots.get(symbol)?.push({ reel: x, row: y });
                }
            }
        }
        DataStore.set(DataTypes.SYMBOL_SPOTS, symbolSpots);
        GameBus.emit('canStop');
    },
};

export default SPIN_PARSER;
