import { Spline } from '../utils/spline';
import { Tween } from '../utils/tween';
import { Controller } from './controller';
import { Container } from '@pixi/display';
import { Graphics } from '@pixi/graphics';

export interface ISplineConfig {
    splines: ISpline[];
}

interface ISpline {
    action: string;
    debug?: boolean;
    easing?: (t: number) => number;
    finish?: () => void;
}

export class SplineController extends Controller {
    private splines: ISpline[];
    private debugContainer: Container;

    public setSplineConfig(config: ISplineConfig) {
        this.splines = config.splines;
        this.splines.forEach((splineConfig) => {
            this.addAction({
                name: splineConfig.action,
                action: async (layer, points: number[], time, tension, resolution, closed) => {
                    const splineData = Spline.process(points, tension, resolution, closed);

                    const tween = new Tween();

                    if (splineConfig.debug) {
                        if (this.debugContainer) {
                            layer.parent.removeChild(this.debugContainer);
                        }
                        this.debugContainer = new Container();
                        layer.parent.addChild(this.debugContainer);
                        this.debugContainer.x = this.layer.x;
                        this.debugContainer.y = this.layer.y;
                        this.debugContainer.removeChildren();
                        const g = new Graphics();

                        this.debugContainer.addChild(g);
                        g.lineStyle(1, 0xffff00);

                        g.moveTo(splineData[0], splineData[1]);
                        for (let i = 2; i < splineData.length; i += 2) {
                            g.lineTo(splineData[i], splineData[i + 1]);
                            g.beginFill(0x00ff00);
                            g.drawCircle(splineData[i], splineData[i + 1], 2);
                            g.endFill();
                        }

                        g.beginFill(0xff0000);
                        for (let i = 0; i < points.length; i += 2) {
                            g.drawCircle(points[i], points[i + 1], 8);
                        }
                        g.endFill();
                    }
                    tween.from(0);
                    tween.to(1);
                    tween.time(time);
                    tween.onUpdate((t) => {
                        const index = 2 * Math.round(((t as number) * (splineData.length - 2)) / 2);
                        const x = splineData[index];
                        const y = splineData[index + 1];

                        this.layer.x = x;
                        this.layer.y = y;
                    });

                    if (splineConfig.easing) {
                        tween.setEasing(splineConfig.easing);
                    }

                    if (splineConfig.finish) {
                        tween.onFinish(splineConfig.finish);
                    }

                    await tween.start();
                },
            });
        });
    }
}
