import { TextStyle } from '@pixi/text';

export const POPUP_STYLE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.8,
    dropShadowBlur: 11,
    dropShadowDistance: 7,
    fill: [0xf1ab1d, 0xffec14, 0xa44d00],
    fontFamily: 'AlfaSlabOne',
    fontSize: 120,
    letterSpacing: 6,
    lineJoin: 'round',
    padding: 13,
    stroke: 0x381705,
    strokeThickness: 5,
};

export const POPUP_STYLE_LARGE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.8,
    dropShadowBlur: 11,
    dropShadowDistance: 7,
    fill: [0xf1ab1d, 0xffec14, 0xa44d00],
    fontFamily: 'AlfaSlabOne',
    fontSize: 160,
    letterSpacing: 6,
    lineJoin: 'round',
    padding: 13,
    stroke: 0x381705,
    strokeThickness: 5,
};

export const POPUP_START_STYLE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.8,
    dropShadowBlur: 11,
    dropShadowDistance: 7,
    fill: [0xf1ab1d, 0xffec14, 0xa44d00],
    fontFamily: 'AlfaSlabOne',
    fontSize: 80,
    letterSpacing: 6,
    lineJoin: 'round',
    padding: 13,
    stroke: 0x381705,
    strokeThickness: 5,
};

export const POPUP_CONTINUE_STYLE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.8,
    dropShadowBlur: 11,
    dropShadowDistance: 7,
    fill: [0xf1ab1d, 0xffec14, 0xa44d00],
    fontFamily: 'AlfaSlabOne',
    fontSize: 65,
    letterSpacing: 6,
    lineJoin: 'round',
    padding: 13,
    stroke: 0x381705,
    strokeThickness: 5,
};

export const POPUP_END_STYLE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.8,
    dropShadowBlur: 11,
    dropShadowDistance: 7,
    fill: [0xf1ab1d, 0xffec14, 0xa44d00],
    fontFamily: 'AlfaSlabOne',
    fontSize: 120,
    letterSpacing: 6,
    lineJoin: 'round',
    padding: 13,
    stroke: 0x381705,
    strokeThickness: 5,
};

export const POPUP_WIN_STYLE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.8,
    dropShadowBlur: 11,
    dropShadowDistance: 7,
    fill: [0xf1ab1d, 0xffec14, 0xa44d00],
    fontFamily: 'AlfaSlabOne',
    fontSize: 200,
    letterSpacing: 6,
    lineJoin: 'round',
    padding: 13,
    stroke: 0x381705,
    strokeThickness: 5,
};
