import SlotMachineFeature, { IFeatureConfig } from './slotMachineFeature';
import SlotMachineEntity from '../slotMachineEntity';
import { timeout } from '../../utils/timeout';
import DataStore from '../../dataStore';
import DataTypes from '../../dataTypes';

export interface IStartFeatureConfig extends IFeatureConfig {
    reelsStartDelay: Array<number>;
    startTime: number;
}

export default class StartFeature extends SlotMachineFeature {
    private reelsStartDelay: Array<number>;
    private startTime: number;

    constructor(config: IStartFeatureConfig) {
        super(config);
        this.reelsStartDelay = config.reelsStartDelay;
        this.startTime = config.startTime;
    }

    public condition(): boolean {
        console.debug('OCC: ', super.checkOverrideCondition());

        return super.checkOverrideCondition();
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        console.debug('START FEATURE');
        DataStore.set(DataTypes.SPIN_IN_PROGRESS, true);
        for (let i = 0; i < slotMachine.reelCount; i++) {
            console.debug('START REEL', i);
            const reel = slotMachine.getReel(i);

            (async () => {
                await timeout(this.reelsStartDelay[i] || 0);
                reel.start(this.startTime);
            })();
        }

        await timeout(this.startTime + Math.max(...this.reelsStartDelay));
        console.debug('START FEATURE END');
    }
}
