import { Controller } from '../../controllers/controller';
import { GameBus } from '../../gameBus';
import { IActionConfig, IEventConfig, ILayoutConfig, ICustomEventConfig } from './layerFactory';
import { Container } from '@pixi/display';
export enum ACTION_ORDER {
    SEQUENCE = 'SEQUENCE',
    PARALLEL = 'PARALLEL',
    RACE = 'RACE',
}
export type ActionHandler = ((layer: Layer) => Promise<void>) | ((layer: Layer) => void);

export class Layer extends Container {
    public name: string;

    private id: string;
    private layoutConfigs: ILayoutConfig[];
    private isRoot: boolean;

    protected controllers: Controller[] = [];
    protected layoutConfig: ILayoutConfig;
    protected childLayers: Array<Layer> = [];
    protected actions: Map<string, ActionHandler> = new Map();

    protected _layerParent: Layer;

    public get layerParent(): Layer {
        return this._layerParent;
    }

    public hasAction(action: string): boolean {
        return (
            this.actions.has(action) ||
            this.controllers.some((controller) => controller.hasAction(action)) ||
            this.childLayers.some((layer) => layer.hasAction(action))
        );
    }

    public addController(controller: Controller) {
        controller.setLayer(this);
        this.controllers.push(controller);
        controller.onAdd();
    }

    public async do(action: string, ...values: any[]): Promise<void> {
        if (this.controllers) {
            for (const controller of this.controllers) {
                await controller.do(this, action, ...values);
            }
        }

        if (this.actions.has(action)) {
            await this.actions.get(action)?.call(this, ...values);
        }

        for (const childLayer of this.childLayers) {
            if (childLayer.hasAction(action)) {
                await childLayer.do(action, ...values);
            }
        }
    }

    public async doParallel(action: string, ...values: any[]): Promise<void> {
        const p = [];

        if (this.controllers) {
            for (const controller of this.controllers) {
                p.push(controller.do(this, action, ...values));
            }
        }

        if (this.actions.has(action)) {
            p.push(this.actions.get(action).call(this, ...values));
        }

        for (const childLayer of this.childLayers) {
            if (childLayer.hasAction(action)) {
                p.push(childLayer.doParallel(action, ...values));
            }
        }

        await Promise.all(p);
    }

    public findRecursive(id: string): Layer | undefined {
        for (const childLayer of this.childLayers) {
            if (childLayer.id === id) {
                return childLayer;
            }
            if (childLayer.findRecursive(id)) {
                return childLayer.findRecursive(id);
            }
        }

        return undefined;
    }

    public setLayoutConfigs(layoutConfigs: ILayoutConfig[]) {
        this.layoutConfigs = layoutConfigs;
    }

    public setID(id: string) {
        this.id = id;
    }

    public addChildLayer(layer: Layer) {
        this.addChild(layer);
        this.childLayers.push(layer);
        layer.doParallel('show');
        layer._layerParent = this;
    }

    public addEvent(event: IEventConfig) {
        GameBus.on(event.event, (values: any) => {
            if (values) {
                if (Array.isArray(values)) {
                    event.handler(this, ...values);
                } else {
                    event.handler(this, values);
                }
            } else {
                event.handler(this);
            }
        });
    }

    public addCustomEvent(event: ICustomEventConfig) {
        GameBus.on(event.customEvent as any, (values: any) => {
            if (values) {
                if (Array.isArray(values)) {
                    event.handler(this, ...values);
                } else {
                    event.handler(this, values);
                }
            } else {
                event.handler(this);
            }
        });
    }

    public addAction(action: IActionConfig) {
        this.actions.set(action.name, (...values) => action.action(this, ...values));
    }

    public setAsRoot() {
        this.isRoot = true;
    }

    public visualUpdate(): void {
        if (!this.parent) {
            return;
        }

        this.buildLayoutConfig();

        if (!this.layoutConfig) {
            return;
        }

        if (this.layoutConfig.scale) {
            this.scale.set(this.layoutConfig.scale.x, this.layoutConfig.scale.y);
        }

        this.setPosition();
        this.setAnchor();
        this.setRotation();
        this.setVisibility();

        if (this.layoutConfig.alpha !== undefined) {
            this.alpha = this.layoutConfig.alpha;
        }

        if (this.layoutConfig.custom) {
            this.layoutConfig.custom(this);
        }

        this.childLayers.forEach((layer) => layer.visualUpdate());
    }

    public getLayoutWidth(): number {
        if (this.layoutConfig.width) {
            if (typeof this.layoutConfig.width === 'string') {
                if (this.isRoot) {
                    return (parseFloat(this.layoutConfig.width) / 100) * window.innerWidth;
                }

                return (
                    (parseFloat(this.layoutConfig.width) / 100) *
                    (this.parent as Layer).getLayoutWidth()
                );
            }

            return this.layoutConfig.width;
        }

        return this.width;
    }

    public getLayoutHeight(): number {
        if (this.layoutConfig.height) {
            if (typeof this.layoutConfig.height === 'string') {
                if (this.isRoot) {
                    return (parseFloat(this.layoutConfig.height) / 100) * window.innerHeight;
                }

                return (
                    (parseFloat(this.layoutConfig.height) / 100) *
                    (this.parent as Layer).getLayoutHeight()
                );
            }

            return this.layoutConfig.height;
        }

        return this.height;
    }

    private buildLayoutConfig() {
        const layoutConfig: any = {};

        if (!this.layoutConfigs) {
            return;
        }
        for (const config of this.layoutConfigs) {
            if (!config.condition || config.condition()) {
                for (const setting in config) {
                    layoutConfig[setting] = (config as any)[setting];
                }
            }
        }

        this.layoutConfig = layoutConfig;
    }

    protected setPosition() {
        if (this.layoutConfig.position) {
            if (this.layoutConfig.position.x !== undefined) {
                if (typeof this.layoutConfig.position.x === 'string') {
                    this.x =
                        (this.parent as Layer).getLayoutWidth() *
                        (parseFloat(this.layoutConfig.position.x) / 100);
                } else {
                    this.x = this.layoutConfig.position.x;
                }
            }

            if (this.layoutConfig.position.y !== undefined) {
                if (typeof this.layoutConfig.position.y === 'string') {
                    this.y =
                        (this.parent as Layer).getLayoutHeight() *
                        (parseFloat(this.layoutConfig.position.y) / 100);
                } else {
                    this.y = this.layoutConfig.position.y;
                }
            }
        }
    }

    protected setAnchor() {
        if (this.layoutConfig.anchor) {
            if (this.layoutConfig.anchor.x !== undefined) {
                this.x -= this.getLayoutWidth() * this.layoutConfig.anchor.x;
            }

            if (this.layoutConfig.anchor.y !== undefined) {
                this.y -= this.getLayoutHeight() * this.layoutConfig.anchor.y;
            }
        }
    }

    protected setRotation() {
        if (Number.isFinite(this.layoutConfig.rotation)) {
            this.rotation = this.layoutConfig.rotation;
        }
    }

    protected setVisibility() {
        if (this.layoutConfig.visible !== undefined) {
            this.visible = this.layoutConfig.visible;
        }
    }
}
