import { Layer } from './layer';
import { Text, TextStyle } from '@pixi/text';
import { Locale } from '../../utils/locale';

export class TextLayer extends Layer {
    private text: Text;
    private maxWidth = Infinity;
    private maxHeight = Infinity;

    constructor() {
        super();
        this.text = new Text('');
        this.addChild(this.text);
    }

    public setText(text: string) {
        this.text.text = Locale.get(text) || text;
        this.visualUpdate();
    }

    public setStyle(style: Partial<TextStyle>) {
        this.text.style = style;
    }

    public async do(action: string, ...values: any[]): Promise<void> {
        if (this.controllers) {
            for (const controller of this.controllers) {
                await controller.do(this, action, ...values);
            }
        }

        if (this.actions.has(action)) {
            const textReturn = await this.actions.get(action).call(this, ...values);

            if (typeof textReturn === 'string') {
                this.setText(textReturn);
            }
            for (let i = 0; i < this.childLayers.length; i++) {
                if (this.childLayers[i].hasAction(action)) {
                    await this.childLayers[i].do(action, values);
                }
            }
        }

        for (const childLayer of this.childLayers) {
            if (childLayer.hasAction(action)) {
                await childLayer.do(action, ...values);
            }
        }
    }

    public override visualUpdate() {
        super.visualUpdate();
        this.text.scale.set(1, 1);
        const widthRatio = this.maxWidth / this.text.width;
        const heightRatio = this.maxWidth / this.text.width;

        if (widthRatio < 1 || heightRatio < 1) {
            if (widthRatio < heightRatio) {
                this.text.scale.set(widthRatio, widthRatio);
            } else {
                this.text.scale.set(heightRatio, heightRatio);
            }
        }
    }

    public setMaxWidth(maxWidth: number): void {
        this.maxWidth = maxWidth;
    }

    public setMaxHeight(maxHeight: number): void {
        this.maxHeight = maxHeight;
    }

    public override setAnchor(): void {
        if (this.layoutConfig.anchor) {
            this.text.anchor.set(this.layoutConfig.anchor.x || 0, this.layoutConfig.anchor.y || 0);
        }
    }
}
