class FeatureController {
    private featuresList: FeaturesList;

    async init(featuresList: FeaturesList) {
        this.featuresList = featuresList;
    }

    get payLines(): boolean {
        return this.featuresList?.payLines === true;
    }

    get placedWilds(): boolean {
        return this.featuresList?.placedWilds === true;
    }

    get bonusSymbol(): boolean {
        return this.featuresList?.bonusSymbol === true;
    }

    get freeSpins(): boolean {
        return this.featuresList?.freeSpins === true;
    }

    get cascade(): boolean {
        return this.featuresList?.cascade === true;
    }

    get logoSeparated(): boolean {
        return this.featuresList?.logoSeparated === true;
    }

    get separatedBackground(): boolean {
        return this.featuresList?.separatedBackground === true;
    }
}

export const features = new FeatureController();

export type FeaturesList = {
    payLines: boolean;
    placedWilds: boolean;
    bonusSymbol: boolean;
    freeSpins: boolean;
    cascade: boolean;
    logoSeparated: boolean;
    separatedBackground: boolean;
};
