import { Layer } from './layer';
import { Graphics } from '@pixi/graphics';

export class QuadLayer extends Layer {
    private graphics: Graphics;

    constructor() {
        super();
        this.graphics = new Graphics();
        this.addChild(this.graphics);
        this.graphics.beginFill(0xffffff);
        this.graphics.drawRect(0, 0, 1, 1);
        this.graphics.endFill();
    }

    public setTint(tint: number) {
        this.graphics.tint = tint;
    }

    public visualUpdate(): void {
        super.visualUpdate();
        this.graphics.width = this.getLayoutWidth();
        this.graphics.height = this.getLayoutHeight();
    }
}
