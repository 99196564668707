import { ITweenConfig, TweenController } from './tweenController';
import { ISplineConfig, SplineController } from './splineController';

export class ControllerFactory {
    public static CreateTween(config: ITweenConfig): TweenController {
        const tweenController = new TweenController();

        tweenController.setTweenConfig(config);

        return tweenController;
    }

    public static CreateSpline(config: ISplineConfig): SplineController {
        const splineController = new SplineController();

        splineController.setSplineConfig(config);

        return splineController;
    }
}
