import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import IWinningsEvent from '../engineEvents/winningsEvent';
import { IWinnings } from '../platformInterface';
import IParser from './IParser';

interface IWinParserConfiguration {
    reelOffset: number;
}

let reelOffset = 0;

const WIN_PARSER: IParser = {
    configure: (configuration: IWinParserConfiguration) => {
        reelOffset = configuration.reelOffset;
    },

    parse: (data: IWinningsEvent) => {
        if (reelOffset === 0) {
            DataStore.set(DataTypes.WINNINGS, data.winnings);
        } else {
            const winnings: IWinnings[] = [];

            data.winnings.forEach((winning) => {
                winnings.push({
                    id: winning.id,
                    coords: winning.coords.slice(reelOffset, winning.coords.length),
                    mainSymbol: winning.mainSymbol,
                    multiWin: winning.multiWin,
                    occurrences: winning.occurrences,
                    prizeType: winning.prizeType,
                    symbols: winning.symbols.slice(reelOffset, winning.symbols.length),
                    winningCoords: winning.winningCoords.slice(
                        reelOffset,
                        winning.winningCoords.length,
                    ),
                    winnings: winning.winnings,
                    winningSymbols: winning.winningSymbols.slice(
                        reelOffset,
                        winning.winningSymbols.length,
                    ),
                    winningType: winning.winningType,
                });
            });
            DataStore.set(DataTypes.WINNINGS, winnings);
        }

        DataStore.set(DataTypes.TOTAL_WIN, data.monetaryWinningsSum);
    },
};

export default WIN_PARSER;
