import { Layer } from './layer';
import { timeout } from '../../utils/timeout';
import { Spine } from 'pixi-spine';
import { Assets } from '@pixi/assets';

export class SpineLayer extends Layer {
    private spine: Spine;
    #spineData: string;

    constructor(spineData: string) {
        super();
        this.#spineData = spineData;
        this.createSpine();
    }

    private createSpine() {
        if (this.spine) {
            this.spine.destroy();
        }

        this.spine = new Spine(Assets.cache.get(`${this.#spineData}.json`).spineData);
        this.addChild(this.spine as any);
    }

    public async play(
        animation: string,
        loop = false,
        trackID = 0,
        iterations = 1,
        timeScale?: number,
    ): Promise<boolean> {
        const animationData = this.getSpine().spineData.findAnimation(animation);

        if (!animationData) {
            console.error(`Animation ${animation} not found for spine ${this.#spineData}`);

            return false;
        }

        for (let i = 0; i < iterations; i++) {
            this.createSpine();

            if (timeScale) {
                this.getSpine().state.timeScale = timeScale;
            }

            this.getSpine().state.setAnimation(trackID, animation, loop);
            await timeout(
                this.getSpine().state.tracks[trackID].animationEnd *
                    1000 *
                    this.getSpine().state.timeScale,
            );
        }

        return true;
    }

    public stop() {
        this.getSpine().state.clearTracks();
    }

    public setSkin(skinName: string) {
        this.spine.skeleton.setSkinByName(skinName);
        this.spine.skeleton.setSlotsToSetupPose();
    }

    public getSpine(): Spine {
        return this.spine;
    }

    public destroy() {
        try {
            this.spine.destroy();
        } catch (error) {
            //
        }

        super.destroy();
    }

    get spineData(): string {
        return this.#spineData;
    }
}
