import DataStore from '../dataStore';
import IFreeSpinsProgressEvent from '../engineEvents/freeSpinsProgressEvent';
import FreeSpinDataTypes from '../freeSpinDataTypes';
import IParser from './IParser';

const FREE_SPINS_PROGRESS_PARSER: IParser = {
    parse: (data: IFreeSpinsProgressEvent) => {
        if (data.context.freeSpinRemaining > 0) {
            DataStore.set(FreeSpinDataTypes.IS_NEXT, true);
        } else {
            DataStore.set(FreeSpinDataTypes.IS_NEXT, false);
        }
        DataStore.set(FreeSpinDataTypes.IS_ACTIVE, true);
        DataStore.set(FreeSpinDataTypes.REMAINING, data.context.freeSpinRemaining);
        DataStore.set(FreeSpinDataTypes.CONSUMED, data.context.freeSpinsConsumed);
        DataStore.set(FreeSpinDataTypes.TOTAL_WIN, data.context.freeSpinsAccumulatedWinnings);
    },
};

export default FREE_SPINS_PROGRESS_PARSER;
