enum DataTypes {
    PANEL = 'PANEL',
    TAXONOMY = 'TAXONOMY',
    CAN_STOP = 'CAN_STOP',
    TOTAL_WIN = 'WIN_AMOUNT',
    WINNINGS = 'WINNINGS',
    ROOT_LAYER = 'ROOT_LAYER',
    ORIENTATION = 'ORIENTATION',
    PAYLINES = 'PAYLINES',
    TOTAL_BET = 'TOTAL_BET',
    RESOURCES = 'RESOURCES',
    RESUME_GAME = 'RESUME_GAME',
    TEXTURES = 'TEXTURES',
    SYMBOL_SPOTS = 'SYMBOL_SPOTS',
    STATE = 'STATE',
    PIXI_APP = 'PIXI_APP',
    AVAILABLE_ACTIONS = 'AVAILABLE_ACTIONS',
    PREVIOUS_PANEL = 'PREVIOUS_PANEL',
    RECENT_TOKENS_AWARDED = 'RECENT_TOKENS_AWARDED',
    LAST_STATE = 'LAST_STATE',
    AVAILABLE_ACTIONS_STATE = 'AVAILABLE_ACTIONS_STATE',
    SPIN_IN_PROGRESS = 'SPIN_IN_PROGRESS',
    BALANCE = 'BALANCE',
    EXPANDED_SYMBOLS = 'EXPANDED_SYMBOLS',
    SPECIAL_SYMBOL = 'SPECIAL_SYMBOL',
    MOUSE_X = 'MOUSE_X',
    MOUSE_Y = 'MOUSE_Y',
    PREVIOUS_BALANCE = 'PREVIOUS_BALANCE',
    BET_LEVEL_DATA = 'BET_LEVEL_DATA',
    WINNINGS_FROM_RECONNECTION = 'WINNINGS_FROM_RECONNECTION',
    PRIZE_TABLE = 'PRIZE_TABLE',
    PRIZE_TABLE_SCALER = 'PRIZE_TABLE_SCALER',
}

export default DataTypes;
