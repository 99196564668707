import DataStore from './dataStore';
import DataTypes from './dataTypes';
import IParser from './parsing/IParser';
import { Application } from '@pixi/app';
import { Audio } from './utils/audio';
import IBalanceEvent from './engineEvents/balanceEvent';
import IShowEvent from './engineEvents/showEvent';
import ISpinEvent from './engineEvents/spinEvent';
import IAvailableActionsEvent from './engineEvents/availableActionsEvent';
import { ISymbolOverrideEvent } from './engineEvents/symbolOverrideEvent';
import IFreeSpinsStartEvent from './engineEvents/freeSpinsStartEvent';
import { IFreeSpinsEndEvent } from './engineEvents/freeSpinsEndEvent';
import IFreeSpinsProgressEvent from './engineEvents/freeSpinsProgressEvent';
import IWinningsEvent from './engineEvents/winningsEvent';
import { GameBus } from './gameBus';
import { Locale } from './utils/locale';
import IConfigEvent from './engineEvents/configEvent';
import { Layer } from './layers/layerTypes/layer';
import { ICascadesStartMessage } from './parsing/cascadeStartParser';
import { ICascadesProgressMessage } from './parsing/cascareProgressParser';
import { ICascadesEndMessage } from './parsing/cascadeEndParser';
import { ISymbolMovementMessage } from './parsing/symbolMovementParser';
import { ITokenAwardedMessage } from './parsing/tokenAwardedParser';
import { DataStoreBrowser } from './utils/dataStoreBrowser';
import { IBetLevelDataMessage } from './parsing/betLevelDataParser';
import { Preload } from './utils/Preload';
import { theme } from './controllers/themeController';
import { features, FeaturesList } from 'game_libs/controllers/featureController';

(window as any).DataStoreBrowser = DataStoreBrowser;

declare const EventManager: any;

declare const MagicUI: any;

interface IParseConfig {
    betLevelDataParsers?: Array<IParser>;
    configParsers?: Array<IParser>;
    balanceParsers?: Array<IParser>;
    showParsers?: Array<IParser>;
    spinParsers?: Array<IParser>;
    availableActionsParsers?: Array<IParser>;
    symbolOverrideParsers?: Array<IParser>;
    symbolMovementParsers?: Array<IParser>;
    freeSpinsStartParsers?: Array<IParser>;
    freeSpinsProgressParsers?: Array<IParser>;
    freeSpinsEndParsers?: Array<IParser>;
    cascadeStartParsers?: Array<IParser>;
    cascadeProgressParsers?: Array<IParser>;
    cascadeEndParsers?: Array<IParser>;
    winParsers?: Array<IParser>;
    tokenAwardedParsers?: Array<IParser>;
    panelMultiplierUpdatedParsers?: Array<IParser>;
}

export interface IGameConfig {
    parseConfig: IParseConfig;
    assetManifest: string;
    audioConfig: Audio.IAudioConfig;
    features: FeaturesList;
}

export enum Orientation {
    LANDSCAPE = 'LANDSCAPE',
    PORTRAIT = 'PORTRAIT',
}

export default class Game {
    private socketClosed = false;
    private paused = false;
    private config: IGameConfig;
    public pixiApp: Application;
    public loadingProgress = 1;
    private preload: Preload;

    constructor(
        gameConfig: IGameConfig,
        onInit: (game: Game) => void,
        onReady: (game: Game) => void,
    ) {
        this.config = gameConfig;

        (window as any).Game = {
            init: async () => {
                await this.loadAssets();
                await theme.init();
                await features.init(this.config.features);
                await this.initialisePixi();

                onInit(this);

                this.showCanvas();

                EventManager.emit('gdk:milestone', {});

                await this.loadAudio();
                MagicUI.showIntro();

                Audio.mute(MagicUI.isMuted());
                this.pixiApp.renderer.background.color = theme.bgColor;
                // DataStoreBrowser.initialise();
            },
        };

        EventManager.on('magic-ui:show-game', () => onReady(this));

        this.addEngineEventParsers();
    }

    setOrientation() {
        if (window.innerWidth > window.innerHeight * 0.7) {
            DataStore.set(DataTypes.ORIENTATION, Orientation.LANDSCAPE);
        } else {
            DataStore.set(DataTypes.ORIENTATION, Orientation.PORTRAIT);
        }
    }

    async loadAudio(): Promise<void> {
        await Audio.load(['music', 'sfx'], 'assets');
        Audio.setAudioConfig(this.config.audioConfig);
    }

    async loadAssets(): Promise<void> {
        this.preload = new Preload(this.config.assetManifest);

        await this.preload.init((progress) => GameBus.emit('loadProgress', progress));

        GameBus.emit('loadProgress', 100);
        GameBus.emit('loadComplete');
    }

    showCanvas() {
        document.getElementById('game-div')?.appendChild(this.pixiApp.view as HTMLCanvasElement);
    }

    async initialisePixi(): Promise<void> {
        DataStore.set(DataTypes.CAN_STOP, true);

        document.getElementById('action')?.classList.add('preload');
        document.getElementById('main-ui')?.classList.add('preload');

        const app = new Application({
            width: window.innerWidth,
            height: window.innerHeight,
            resolution: 2,
            autoDensity: true,
            antialias: true,
            hello: true,
        });

/////////////////////
//////////////////////////////
//////////////////////////////////////////////////////////
///////////
//////////////////

        this.pixiApp = app;
        this.render();

        DataStore.set(DataTypes.PIXI_APP, app);

        window.addEventListener('resize', () => {
            app.renderer.resize(window.innerWidth, window.innerHeight);
            this.setOrientation();
        });

        window.addEventListener('mousemove', (evt) => {
            DataStore.set(DataTypes.MOUSE_X, evt.clientX);
            DataStore.set(DataTypes.MOUSE_Y, evt.clientY);
        });

        this.setOrientation();
        await Locale.load('en');
    }

    public addScreen(screen: Layer, index?: number) {
        window.addEventListener('resize', () => {
            screen.visualUpdate();
        });
        screen.visualUpdate();
        if (index !== undefined) {
            this.pixiApp.stage.addChildAt(screen, index);
        } else {
            this.pixiApp.stage.addChild(screen);
        }
        screen.visualUpdate();
    }

    public removeScreen(screen: Layer) {
        this.pixiApp.stage.removeChild(screen);
    }

    private printEngineEvent(eventType: string, eventData: any) {
        console.debug(`%c${eventType}`, 'color: #00FF00');
        console.debug(eventData);
    }

    addEngineEventParsers() {
        EventManager.on('magic-engine:available-actions', (data: IAvailableActionsEvent) => {
            this.config.parseConfig.availableActionsParsers?.forEach((parser) =>
                parser.parse(data),
            );
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:spin', (data: ISpinEvent) => {
            this.config.parseConfig.spinParsers?.forEach((parser) => parser.parse(data));
            EventManager.emit('game:winnings', { amount: 0 });
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:show', (data: IShowEvent) => {
            this.config.parseConfig.showParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('gdk:balance', (data: IBalanceEvent) => {
            this.config.parseConfig.balanceParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:freeSpinsStart', (data: IFreeSpinsStartEvent) => {
            this.config.parseConfig.freeSpinsStartParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:freeSpinsEnd', (data: IFreeSpinsEndEvent) => {
            this.config.parseConfig.freeSpinsEndParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:freeSpinsProgress', (data: IFreeSpinsProgressEvent) => {
            this.config.parseConfig.freeSpinsProgressParsers?.forEach((parser) =>
                parser.parse(data),
            );
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:winnings', (data: IWinningsEvent) => {
            this.config.parseConfig.winParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:cascadesStart', (data: ICascadesStartMessage) => {
            this.config.parseConfig.cascadeStartParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:cascadesProgress', (data: ICascadesProgressMessage) => {
            this.config.parseConfig.cascadeProgressParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:betLevelData', (data: IBetLevelDataMessage) => {
            this.config.parseConfig.betLevelDataParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:tokenAwarded', (data: ITokenAwardedMessage) => {
            this.config.parseConfig.tokenAwardedParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:cascadesEnd', (data: ICascadesEndMessage) => {
            this.config.parseConfig.cascadeEndParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:symbolOverride', (data: ISymbolOverrideEvent) => {
            this.config.parseConfig.symbolOverrideParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('magic-engine:symbolMovement', (data: ISymbolMovementMessage) => {
            this.config.parseConfig.symbolMovementParsers?.forEach((parser) => parser.parse(data));
            this.printEngineEvent(data.type, data);
        });

        EventManager.on('gdk:close', () => {
            this.socketClosed = true;
        });

        EventManager.on('gdk:connected', () => {
            this.socketClosed = false;
        });

        EventManager.on('magic-ui:sound', (soundState: any) => {
            Audio.mute(!soundState.state);
        });

        EventManager.on('magic-engine:config', async (data: IConfigEvent) => {
            MagicUI.setGameRTP(data.rtp);
            this.config.parseConfig.configParsers?.forEach((parser) => parser.parse(data));
        });
    }

    render() {
        if (!this.paused) {
            this.pixiApp.render();
        }

        requestAnimationFrame(this.render.bind(this));
    }
}
