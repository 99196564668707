enum SlotDataTypes {
    SYMBOL_TRANSITIONS = 'SYMBOL_TRANSITIONS',
    CASCADING = 'CASCADING',
    NEW_PANEL = 'NEW_PANEL',
    EXPANDING_SYMBOLS = 'EXPANDING_SYMBOLS',
    CASCADE_NEXT = 'CASCADE_NEXT',
    OVERRIDE_SYMBOLS = 'OVERRIDE_SYMBOLS',
    BIG_WIN_TARGET = 'BIG_WIN_TARGET',
}

export default SlotDataTypes;
