import type { AssetsManifest } from '@pixi/assets';
import '@pixi/spritesheet';
import { Assets } from '@pixi/assets';

export type ProgressFunction = (progress: number) => void;

export class Preload {
    readonly name = 'Preload';
    private assetsManifest: AssetsManifest = { bundles: [] };

    private loadedBundles: string[] = [];

    constructor(private readonly manifest: string) {}

    async init(onProgress?: ProgressFunction) {
        this.assetsManifest = await this.fetchAssetsManifest(this.manifest);
        await Assets.init({ manifest: this.assetsManifest, basePath: 'assets' });
        await this.loadBundles(['default'], onProgress);
    }

    private checkBundleExists(bundle: string) {
        return !!this.assetsManifest.bundles.find((b) => b.name === bundle);
    }

    async loadBundles(bundles: string | string[], onProgress?: ProgressFunction) {
        if (typeof bundles === 'string') bundles = [bundles];

        bundles = bundles.map((bundle) => {
            if (bundle.includes('{style}')) {
                console.error(`[Theme] Style is not set`);
            }

            return bundle;
        });

        // Check bundles requested if they exists
        for (const bundle of bundles) {
            if (!this.checkBundleExists(bundle)) {
                throw new Error(`[Assets] Invalid bundle: ${bundle}`);
            }
        }

        // Filter out bundles already loaded
        const loadList = bundles.filter((bundle) => !this.loadedBundles.includes(bundle));

        // Skip if there is no bundle left to be loaded
        if (!loadList.length) return;

        // Load bundles
        await Assets.loadBundle(loadList, (progress) => {
            if (onProgress) {
                onProgress(progress * 100);
            }
        });

        // Append loaded bundles to the loaded list
        this.loadedBundles.push(...loadList);
    }

    bundlesLoaded(bundles: string[]) {
        for (const name of bundles) {
            // Return false if a single bundle is not present in the loaded list
            if (!this.loadedBundles.includes(name)) {
                return false;
            }
        }

        // All provided bundles are loaded
        return true;
    }

    private async fetchAssetsManifest(url: string): Promise<AssetsManifest> {
        const response = await fetch(url);
        const manifest = await response.json();

        if (!manifest.bundles) {
            throw new Error('[Assets] Invalid assets manifest');
        }

        return manifest;
    }
}
