import { timeout } from '../utils/timeout';
import { Tween } from '../utils/tween';
import { Container, DisplayObject } from '@pixi/display';
import { Ticker } from '@pixi/core';
import { easing } from '../utils/easing';
import { GameBus } from '../gameBus';
import { SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';

export interface IReelConfig {
    direction: ReelDirection;
    spinSpeed: number;
    chunkSize: number;
    rows: number;
}

export enum ReelDirection {
    UP,
    DOWN,
}

export default class ReelEntity extends Container {
    private symbols: Array<DisplayObject> = [];
    private symbolsIDs: Array<string> = [];
    private symHeight: number;
    private symWidth: number;
    private rows: number;
    private spinSpeed: number;
    private currentSpeed = 0;
    private ticker: Ticker;
    private direction: number;
    private symbolFactory: (symbolID: string, finalPanel: boolean) => DisplayObject;
    private chunkIndex = -1;
    private running: boolean;
    private chunkSize: number;
    private symbolIDs: string[];
    private chunks: Array<Array<DisplayObject>> = [];
    private symbolContainer = new Container();
    private finalPanel: string[];
    private expandedSymbol: DisplayObject;

    constructor(config: IReelConfig) {
        super();

        this.chunkSize = config.chunkSize;
        this.direction = config.direction;
        this.spinSpeed = config.spinSpeed;
        this.rows = config.rows;

        this.ticker = Ticker.shared;
        this.ticker.add(this.update, this);
        this.ticker.start();

        this.addChild(this.symbolContainer);
    }

    public setSymbolFactory(
        symbolFactory: (symbolID: string, finalPanel: boolean) => DisplayObject,
    ) {
        this.symbolFactory = symbolFactory;
    }

    public setSymbolsScale(scale: number) {
        this.symbols.forEach((symbol) => symbol.scale.set(scale));
    }

    public setSymbolDimensions(width: number, height: number) {
        this.symWidth = width;
        this.symHeight = height;
    }

    public setSymbol(symbolID: string, row: number) {
        if (this.symbols[row]) {
            this.symbols[row].destroy();
        }

        this.symbolContainer.removeChild(this.symbols[row]);
        const symbol = this.symbolFactory(symbolID, true);

        this.symbols[row] = symbol;
        this.symbolsIDs[row] = symbolID;
        this.symbolContainer.addChild(symbol);
        symbol.x = this.symWidth / 2;
        symbol.y = row * this.symHeight + this.symHeight / 2;
    }

    public get speed(): number {
        return this.spinSpeed;
    }

    public get symbolHeight(): number {
        return this.symHeight;
    }

    public addSymbol(symbolID: string, index: number) {
        const symbol = this.symbolFactory(symbolID, true);

        if (this.symbols[index]) {
            this.symbols[index].destroy();
        }

        this.symbols[index] = symbol;
        this.symbolsIDs[index] = symbolID;

        symbol.x = this.symWidth / 2;
        symbol.y = index * this.symHeight + this.symHeight / 2;
        this.symbolContainer.addChild(symbol);
    }

    public getSymbol(index: number): DisplayObject {
        return this.symbols[index];
    }

    public addFinalDisplaySymbols(symbols: DisplayObject[]) {
        for (let i = 0; i < 3; i++) {
            const index = this.symbols.length - (this.rows - 1);
            const randomSymbol = this.generateRandomSymbol();

            randomSymbol.symbol.x = this.symWidth / 2;
            randomSymbol.symbol.y = -index * this.symHeight + 60;
            this.symbols.push(randomSymbol.symbol);
            this.symbolsIDs.push(randomSymbol.symbolID);
            this.symbolContainer.addChild(randomSymbol.symbol);
        }

        symbols.forEach((symbol) => {
            const index = this.symbols.length - (this.rows - 1);

            this.symbols[index] = symbol;
            symbol.x = this.symWidth / 2;
            symbol.y = -index * this.symHeight;
            this.symbols.push(symbol);
        });

        symbols.reverse().forEach((symbol) => {
            this.symbolContainer.addChild(symbol);
        });
    }

    public addDisplaySymbol(symbol: DisplayObject): DisplayObject {
        const index = this.symbols.length - (this.rows - 1);

        this.symbols[index] = symbol;
        symbol.x = this.symWidth / 2;
        symbol.y = -index * this.symHeight;
        this.symbolContainer.addChild(symbol);

        this.symbols.push(symbol);

        return symbol;
    }

    private retireSymbols() {
        this.symbols?.forEach((oldSymbol, index) => {
            const symbolID = this.symbolsIDs[index];

            if (!symbolID) {
                return;
            }

            const symbol = this.symbolFactory(symbolID, false);

            if (oldSymbol) {
                symbol.x = oldSymbol.x;
                symbol.y = oldSymbol.y;
                oldSymbol.destroy();
            } else {
                symbol.x = this.symWidth / 2;
                symbol.y = -index * this.symHeight;
            }

            symbol.visible = this.symbols[index]?.visible ?? true;

            this.symbols[index] = symbol;

            this.symbolContainer.addChild(symbol);
        });
    }

    public start(startTime: number) {
        this.retireSymbols();
        this.expandedSymbol = null;
        console.debug('REEL STARTING');
        new Tween()
            .from(0)
            .to(1)
            .time(startTime)
            .onUpdate((current) => {
                if (typeof current === 'number') {
                    this.setSpeedFactor(current);
                }
            })
            .start();
        this.finalPanel = [];
        this.running = true;
    }

    public setFinalPanel(symbols: Array<string>) {
        this.finalPanel = symbols.slice(0).reverse();
    }

    public async stop(stopTime: number, delay: number): Promise<void> {
        await timeout(delay);

        return new Promise((resolve) => {
            const symbols: DisplayObject[] = [];

            this.finalPanel.forEach((symbol) => {
                const symbolEntity = this.symbolFactory(symbol, true);

                symbols.push(symbolEntity);
            });

            this.addFinalDisplaySymbols(symbols);

            (async () => {
                await timeout(stopTime - 120);
                GameBus.emit('reelStopping', this.finalPanel);
            })();

            new Tween()
                .from(this.symbolContainer.y)
                .to(this.symbols[this.symbols.length - 1].y * -this.direction + this.symHeight / 2)
                .time(stopTime)
                .setEasing(easing.outQuart)
                .onUpdate((current) => {
                    if (typeof current === 'number') {
                        this.symbolContainer.y = current;
                    }
                })
                .onFinish(() => {
                    GameBus.emit('reelStop', this.finalPanel);
                    resolve();
                })
                .start();

            this.running = false;
        });
    }

    public reset() {
        this.expandedSymbol = null;
        this.currentSpeed = 0;
        this.symbolContainer.y = 0;

        this.symbols.forEach((symbol) => {
            if (symbol instanceof SpineLayer) {
                symbol.destroy();
            } else {
                this.symbolContainer.removeChild(symbol);
            }
        });

        this.symbolContainer.removeChildren();

        this.symbols = [];
        this.symbolsIDs = [];
        this.chunks = [];
        this.chunkIndex = -1;
        this.running = false;
    }

    public setSpeedFactor(speed: number) {
        this.currentSpeed = this.spinSpeed * speed;
    }

    public countSymbols(symbolID: string) {
        let symbolCount = 0;

        this.finalPanel.forEach((symbol) => {
            if (symbol === symbolID) {
                symbolCount++;
            }
        });

        return symbolCount;
    }

    public getSymbolRows(symbolID: string): number[] {
        const rows: number[] = [];

        this.finalPanel.forEach((symbol, row) => {
            if (symbol === symbolID) {
                rows.push(row);
            }
        });

        return rows;
    }

    public setSymbolIDs(symbolIDs: Array<string>) {
        this.symbolIDs = symbolIDs;
    }

    async expand() {
        if (!this.isExpandable) {
            return;
        }

        this.symbols?.forEach((symbol) => {
            if ((symbol as any).spineData === 'WI_EX' && !this.expandedSymbol) {
                this.expandedSymbol = symbol;
            } else {
                symbol.visible = false;
            }
        });

        await new Tween()
            .from(this.expandedSymbol.y)
            .to(1130)
            .time(500)
            .onUpdate((current) => {
                if (typeof current === 'number' && this.expandedSymbol) {
                    this.expandedSymbol.y = current;
                }
            })
            .start();
    }

    get isExpandable() {
        return (
            this.symbols.some((symbol) => (symbol as any).spineData === 'WI_EX') && !this.isExpanded
        );
    }

    get isExpanded() {
        return this.expandedSymbol !== null;
    }

    private addChunk(index: number) {
        this.chunks[index] = [];
        for (let i = 0; i < this.chunkSize; i++) {
            this.chunks[index].push(this.addDisplaySymbol(this.generateRandomSymbol().symbol));
        }
    }

    private removeChunk(index: number) {
        if (index < 0) {
            return;
        }
        for (let i = 0; i < this.chunkSize; i++) {
            this.chunks[index]?.forEach((symbol) => {
                if (symbol instanceof SpineLayer) {
                    symbol.destroy();
                } else {
                    this.symbolContainer.removeChild(symbol);
                }
            });
        }
        this.chunks[index] = [];
    }

    private generateRandomSymbol(): {
        symbolID: string;
        symbol: DisplayObject;
    } {
        const id = Math.floor(Math.random() * this.symbolIDs.length);

        return {
            symbolID: this.symbolIDs[id],
            symbol: this.symbolFactory(this.symbolIDs[id], false),
        };
    }

    private update() {
        if (!this.running) {
            return;
        }
        this.symbolContainer.y += this.currentSpeed * this.ticker.deltaMS * this.direction;
        const chunkIndex = Math.floor(this.symbolContainer.y / this.symHeight / this.chunkSize);

        if (this.chunkIndex < chunkIndex) {
            this.addChunk(chunkIndex);
            this.removeChunk(chunkIndex - 2);

            this.chunkIndex = chunkIndex;
        }
    }

    deactivateAllSymbols() {
        this.symbols.forEach((symbol) => {
            if (symbol instanceof SpineLayer) {
                if (symbol.spineData !== 'WI_EX') {
                    symbol.play('inactive');
                }
            }
        });
    }
}
