import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import IBalanceEvent from '../engineEvents/balanceEvent';
import IParser from './IParser';

const BALANCE_PARSER: IParser = {
    parse: (data: IBalanceEvent) => {
        DataStore.set(DataTypes.PREVIOUS_BALANCE, DataStore.get(DataTypes.BALANCE));
        DataStore.set(DataTypes.BALANCE, data.amount);
    },
};

export default BALANCE_PARSER;
