import { Sprite } from '@pixi/sprite';
import { Layer } from './layer';
import { Texture } from '@pixi/core';
export class InteractionLayer extends Layer {
    private interactionArea: Sprite;
    private onClickHandler: (layer: Layer) => void;
    private onOverHandler: (layer: Layer) => void;
    private onDownHandler: (layer: Layer) => void;
    private onOutHandler: (layer: Layer) => void;
    private onUpHandler: (layer: Layer) => void;

    constructor(debug?: boolean) {
        super();

        this.interactionArea = Sprite.from(debug ? Texture.WHITE : Texture.EMPTY);

        this.addChild(this.interactionArea);

        this.interactionArea.alpha = 0.3;
        this.interactionArea.anchor.set(0.5);
        this.resize();

        window.addEventListener('resize', () => this.resize());
    }

    private resize() {
        this.interactionArea.width = window.innerWidth * 2;
        this.interactionArea.height = window.innerHeight * 2;
    }

    public setOnClickHandler(handler: (layer: Layer) => void): void {
        this.onClickHandler = handler;
        this.eventMode = 'static';
        this.on('pointertap', () => {
            this.onClickHandler(this);
        });
        this.enable();
    }

    public setOnDownHandler(handler: (layer: Layer) => void): void {
        this.onDownHandler = handler;
        this.eventMode = 'static';
        this.on('pointerdown', () => {
            this.onDownHandler(this);
        });
        this.enable();
    }

    public setOnUpHandler(handler: (layer: Layer) => void): void {
        this.onUpHandler = handler;
        this.interactive = true;
        this.on('pointerup', () => {
            this.onUpHandler(this);
        });
        this.enable();
    }

    public setOnOverHandler(handler: (layer: Layer) => void): void {
        this.onOverHandler = handler;
        this.eventMode = 'static';
        this.on('pointerover', () => {
            this.onOverHandler(this);
        });
        this.enable();
    }

    public setOnOutHandler(handler: (layer: Layer) => void): void {
        this.onOutHandler = handler;
        this.eventMode = 'static';
        this.on('pointerout', () => {
            this.onOutHandler(this);
        });
        this.enable();
    }

    public enableDebug() {
        this.interactionArea.texture = Texture.WHITE;
    }

    public disableDebug() {
        this.interactionArea.texture = Texture.EMPTY;
    }

    public enable() {
        this.eventMode = 'static';
        this.cursor = 'pointer';
    }

    public disable() {
        this.eventMode = 'static';
        this.cursor = 'default';
    }

    public override visualUpdate(): void {
        // super.visualUpdate();
        // this.interactionArea.width = this.getLayoutWidth();
        // this.interactionArea.height = this.getLayoutHeight();
    }
}
