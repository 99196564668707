import { SpriteLayer } from './spriteLayer';

export class ButtonLayer extends SpriteLayer {
    private onClickHandler: (layer: ButtonLayer) => void;

    constructor() {
        super();
        this.enable();
    }

    public enable() {
        this.interactive = true;
    }

    public disable() {
        this.interactive = false;
    }

    public setOnClickHandler(handler: (layer: ButtonLayer) => void): void {
        this.onClickHandler = handler;

        this.on(
            'pointerdown',
            () => {
                this.onClickHandler(this);
            },
            this,
        );
    }
}
