import { Controller } from 'game_libs/controllers/controller';
import { GameBus } from 'game_libs/gameBus';
import { DisplayObject, Container } from '@pixi/display';

export enum BONUS_SYMBOL_ANIMATION_ACTIONS {
    ANIMATE_BONUS_ANIMATION = 'ANIMATE_BONUS_ANIMATION',
    HIDE_ANIMATIONS = 'HIDE_ANIMATIONS',
}

export interface ISymbolAnimationControllerConfig {
    reelSpacing: number;
    symbolWidth: number;
    symbolHeight: number;
    rows: number;
    columns: number;
    symbolFactory: () => Container;
    playBonusLandAnimation: (spine: Container) => Promise<void>;
}

export class BonusSymbolAnimation extends Controller {
    private config: ISymbolAnimationControllerConfig;
    private animations: DisplayObject[] = [];

    constructor(config: ISymbolAnimationControllerConfig) {
        super();
        this.config = config;
        // setTimeout(() => {
        // // @ts-ignore
        // this.animateWildMultiplier(this.layer, window.SLOT_MACHINE,
        //     {
        //         inCoord: {
        //             reel:1,
        //             row: 0
        //         },
        //         new: "WI_X2",
        //         original: "WI"
        //     }
        // )
        // }, 2000);
    }

    public onAdd(): void {
        // this.layer.addAction({
        //     name: BONUS_SYMBOL_ANIMATION_ACTIONS.ANIMATE_BONUS_ANIMATION,
        //     action: this.animationBonusSymbol.bind(this)
        // });
        // this.layer.addAction({
        //     name: BONUS_SYMBOL_ANIMATION_ACTIONS.HIDE_ANIMATIONS,
        //     action: this.hide.bind(this)
        // });

        GameBus.on('spinStart', () => {
            this.layer.visible = false;
        });
    }

    // private async animationBonusSymbol(layer: Layer, slotMachine: SlotMachineEntity, reel: number, row: number) {
    //     // this.layer.visible = true;
    //     // const panel = DataStore.get<any>(DataTypes.PANEL);
    //     // const animations: Promise<void>[] = [];

    //     // slotMachine.hideSymbol(reel, row);

    //     // const symbol = this.config.symbolFactory();

    //     // symbol.x = reel * this.config.reelSpacing + (this.config.symbolWidth / 2);
    //     // symbol.y = row * this.config.symbolHeight + (this.config.symbolHeight / 2);
    //     // this.animations.push(symbol);
    //     // animations.push(this.config.playBonusLandAnimation(symbol));
    //     // this.layer.addChild(symbol);
    // }

    // public async hide(layer: Layer, action: string) {
    //     this.animations.forEach(animation => this.layer.removeChild(animation));
    //     this.animations = [];
    // }
}
