import { IFromTo, Tween } from '../utils/tween';
import { Controller } from './controller';

export interface ITweenConfig {
    tweens: ITween[];
}

interface ITween {
    id?: string;
    startAction: string;
    stopAction?: string;
    from?: IFromTo | number;
    to?: IFromTo | number;
    time?: number;
    custom?: (t: Tween, ...params: any[]) => Promise<void>;
    easing?: (t: number) => number;
    update?: (from: IFromTo | number) => void;
    finish?: () => void;
}

export class TweenController extends Controller {
    private tweens: ITween[];

    public setTweenConfig(config: ITweenConfig) {
        this.tweens = config.tweens;
        this.tweens.forEach((tweenConfig) => {
            this.addAction({
                name: tweenConfig.startAction,
                action: async (...params) => {
                    const t = new Tween();

                    if (tweenConfig.from) {
                        t.from(tweenConfig.from);
                    }

                    if (tweenConfig.id) {
                        t.setID(tweenConfig.id);
                    }

                    if (tweenConfig.to) {
                        t.to(tweenConfig.to);
                    }

                    if (tweenConfig.time) {
                        t.time(tweenConfig.time);
                    }

                    if (tweenConfig.easing) {
                        t.setEasing(tweenConfig.easing);
                    }

                    if (tweenConfig.update) {
                        t.onUpdate(tweenConfig.update);
                    }

                    if (tweenConfig.finish) {
                        t.onFinish(tweenConfig.finish);
                    }

                    if (!tweenConfig.custom) {
                        t.connect(this.layer);
                        await t.start();
                    } else {
                        await tweenConfig.custom(t, ...params);
                    }
                },
            });
        });
    }
}
