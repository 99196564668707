import { timeout } from '../../utils/timeout';
import SlotMachineFeature, { IFeatureConfig } from './slotMachineFeature';
import SlotMachineEntity from '../slotMachineEntity';
import DataStore from '../../dataStore';
import DataTypes from '../../dataTypes';
import ReelEntity from '../reelEntity';
import { GameBus } from '../../gameBus';

export interface IStopFeatureConfig extends IFeatureConfig {
    reelsStopDelay: Array<number>;
    stopTime: number;
    anticipationRule: (
        reel: ReelEntity,
        reelIndex: number,
        slotMachine: SlotMachineEntity,
    ) => boolean;
    anticipationTime?: number;
}

export default class StopFeature extends SlotMachineFeature {
    private stopTime: number;
    private reelsStopDelay: number[];
    private anticipationRule: (
        reel: ReelEntity,
        reelIndex: number,
        slotMachine: SlotMachineEntity,
    ) => boolean;
    private anticipating = false;
    private anticipationTime = 3000;

    constructor(config: IStopFeatureConfig) {
        super(config);
        this.stopTime = config.stopTime;
        this.reelsStopDelay = config.reelsStopDelay;
        this.anticipationRule = config.anticipationRule || (() => false);
        if (!isNaN(config.anticipationTime)) {
            this.anticipationTime = config.anticipationTime;
        }
    }

    public condition(): boolean {
        return super.checkOverrideCondition();
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        console.debug('STOP FEATURE');
        const panel = DataStore.get<any>(DataTypes.PANEL);
        const reelStops: Array<Promise<void>> = [];

        for (let i = 0; i < slotMachine.reelCount; i++) {
            const reel = slotMachine.getReel(i);

            reel.setFinalPanel(panel[i]);
            if (this.anticipationRule(reel, i, slotMachine)) {
                (async () => {
                    await timeout(this.stopTime + this.reelsStopDelay[i] || 0);
                    if (!this.anticipating) {
                        this.anticipating = true;
                        GameBus.emit('anticipationStart', i, slotMachine);
                    } else {
                        GameBus.emit('anticipationContinue', i, slotMachine);
                    }
                })();

                await timeout(this.anticipationTime);
            }

            const p = reel.stop(this.stopTime, this.reelsStopDelay[i] || 0);

            reelStops.push(p);
        }

        await Promise.all(reelStops);
        if (this.anticipating) {
            GameBus.emit('anticipationStop');
            this.anticipating = false;
        }
        for (let i = 0; i < slotMachine.reelCount; i++) {
            slotMachine.getReel(i).reset();
        }
        slotMachine.setPanel(DataStore.get(DataTypes.PANEL));
        DataStore.set(DataTypes.SPIN_IN_PROGRESS, false);
        await timeout(200);

        GameBus.emit('spinStop');
    }
}
