import { Controller } from '../../controllers/controller';
import { IGameEvents } from '../../gameBus';
import { ACTION_ORDER, Layer } from './layer';
import { TextLayer } from './textLayer';
import { SpriteLayer } from './spriteLayer';
import { TilingSpriteLayer } from './tilingSpriteLayer';
import { InteractionLayer } from './interactionLayer';
import { EventEmitter } from 'events';
import { SpineLayer } from './spineLayer';
import { QuadLayer } from './quadLayer';

import { TextStyle } from '@pixi/text';
import { ButtonLayer } from './buttonLayer';

import { SpriteAnimationLayer } from './spriteAnimationLayer';
import { ParticleEmitterLayer } from './particleEmitterLayer';
import { EmitterConfigV3 } from '@pixi/particle-emitter';

import '@pixi/events';
import '@pixi/mixin-get-global-position';
import { PrizeTickerLayer } from './prizeTickerLayer';

export enum LayerType {
    CONTAINER,
    TEXT,
    SPRITE,
    TILING_SPRITE,
    INTERACTION_LAYER,
    SPINE,
    QUAD,
    BUTTON,
    SPRITE_ANIMATED,
    PARTICLE_EMITTER,
    PRIZE_TICKER,
}

export interface ILayerConfig {
    id?: string;
    actions?: Array<IActionConfig>;
    bus?: EventEmitter;
    events?: Array<IEventConfig | ICustomEventConfig>;
    layerChildren?: Layer[];
    layout?: ILayoutConfig | ILayoutConfig[];
    controllers?: Controller[];
    actionOrder?: ACTION_ORDER;
    start?: (layer: Layer) => void;
}

export interface ITextLayerConfig extends ILayerConfig {
    text?: string;
    style?: Partial<TextStyle>;
    maxWidth?: number;
    maxHeight?: number;
    layout?: ILayoutConfig;
}

export interface ISpriteLayerConfig extends ILayerConfig {
    texture?: string;
    anchor?: IAnchorVector;
    scale?: number;
}

export interface IAnimatedSpriteConfig extends ILayerConfig {
    textures?: string[];
    anchor?: IAnchorVector;
    scale?: number;
}

export interface IPrizeTickerConfig extends ILayerConfig {
    duration: number;
    width: number;
    style: Partial<TextStyle>;
    useDecimals?: boolean;
}

export interface ITilingSpriteLayerConfig extends ISpriteLayerConfig {
    tilingWidth?: number;
    tilingHeight?: number;
}

export interface IParticleEmitterLayerConfig extends ILayerConfig {
    emitter: EmitterConfigV3;
}

export interface IInteractionLayerConfig extends ILayerConfig {
    onClick?: (layer: Layer) => void;
    onOver?: (layer: Layer) => void;
    onDown?: (layer: Layer) => void;
    onOut?: (layer: Layer) => void;
    onUp?: (layer: Layer) => void;
    debug?: boolean;
}

export interface IButtonLayerConfig extends ILayerConfig {
    onClick?: (layer: ButtonLayer) => void;
    texture: string;
}

export interface ISpineConfig extends ILayerConfig {
    spineData: string;
}

export interface IQuadConfig extends ILayerConfig {
    tint?: number;
}

export interface ILayoutConfig {
    condition?: () => boolean;
    custom?: (layer: Layer) => void;
    alpha?: number;
    width?: number | string;
    height?: number | string;
    anchor?: IAnchorVector;
    position?: IPositionVector;
    rotation?: number;
    scale?: IAnchorVector;
    ignoreAspectRatio?: boolean;
    visible?: boolean;
}

interface IAnchorVector {
    x?: number;
    y?: number;
}

interface IPositionVector {
    x?: number | string;
    y?: number | string;
}

export enum PositionMode {
    PERCENT,
    NORMAL,
}

export interface IActionConfig {
    name: string;
    action: (layer: Partial<Layer>, ...values: any[]) => Promise<void> | void;
}

export interface IEventConfig {
    event: keyof IGameEvents;
    handler: (layer: Layer, ...values: any[]) => Promise<void> | void;
}

export interface ICustomEventConfig {
    customEvent: string;
    handler: (layer: Layer, ...values: any[]) => Promise<void> | void;
}

export class LayerFactory {
    public static Create(type: LayerType, config: ILayerConfig): Layer {
        let layer: Layer;

        switch (type) {
            case LayerType.CONTAINER:
                layer = new Layer();
                break;
            case LayerType.TEXT:
                layer = new TextLayer();
                break;
            case LayerType.SPINE:
                layer = new SpineLayer((config as ISpineConfig).spineData);
                break;
            case LayerType.SPRITE:
                layer = new SpriteLayer();
                break;
            case LayerType.QUAD:
                layer = new QuadLayer();
                break;
            case LayerType.TILING_SPRITE:
                layer = new TilingSpriteLayer();
                break;
            case LayerType.INTERACTION_LAYER:
                layer = new InteractionLayer();
                break;
            case LayerType.SPRITE_ANIMATED:
                layer = new SpriteAnimationLayer();
                break;
            case LayerType.BUTTON:
                layer = new ButtonLayer();
                break;
            case LayerType.PARTICLE_EMITTER:
                layer = new ParticleEmitterLayer();
                break;
            case LayerType.PRIZE_TICKER:
                layer = new PrizeTickerLayer();
                break;
        }

        if (config.id) {
            layer.setID(config.id);
            layer.name = config.id;
        }

        if (config.actions) {
            config.actions.forEach((action) => layer.addAction(action));
        }

        if (config.controllers) {
            config.controllers.forEach((controller) => layer.addController(controller));
        }

        if (config.events) {
            config.events.forEach((event) => {
                if ('event' in event) {
                    layer.addEvent(event);
                } else {
                    layer.addCustomEvent(event);
                }
            });
        }

        if (config.layerChildren) {
            config.layerChildren.forEach((childLayer) => layer.addChildLayer(childLayer));
        }

        if (!config.layout) {
            config.layout = {};
            config.layout.position = { x: 0, y: 0 };
            layer.setLayoutConfigs([config.layout]);
        } else if (Array.isArray(config.layout)) {
            layer.setLayoutConfigs(config.layout);
        } else {
            layer.setLayoutConfigs([config.layout]);
        }

        config.start && config.start(layer);

        return layer;
    }

    public static CreateContainer(config: ILayerConfig) {
        return LayerFactory.Create(LayerType.CONTAINER, config) as Layer;
    }

    public static CreateText(config: ITextLayerConfig) {
        const textLayer = LayerFactory.Create(LayerType.TEXT, config) as TextLayer;

        if (config.text) {
            textLayer.setText(config.text);
        }

        if (config.style) {
            textLayer.setStyle(config.style);
        }

        if (config.maxWidth) {
            textLayer.setMaxWidth(config.maxWidth);
        }

        if (config.layout) {
            textLayer.setLayoutConfigs([config.layout]);
            textLayer.visualUpdate();
        }

        if (config.maxHeight) {
            textLayer.setMaxHeight(config.maxHeight);
        }

        return textLayer;
    }

    public static CreateSprite(config: ISpriteLayerConfig) {
        const spriteLayer = LayerFactory.Create(LayerType.SPRITE, config) as SpriteLayer;

        if (config.texture) {
            spriteLayer.setTexture(config.texture);
        }

        if (config.scale) {
            spriteLayer.scale.set(config.scale, config.scale);
        }

        if (config.anchor) {
            spriteLayer.setSpriteAnchor(config.anchor.x, config.anchor.y);
        }

        return spriteLayer;
    }

    public static CreateParticleEmitter(config: IParticleEmitterLayerConfig) {
        const emitterLayer = LayerFactory.Create(
            LayerType.PARTICLE_EMITTER,
            config,
        ) as ParticleEmitterLayer;

        if (config.emitter) {
            emitterLayer.setConfig(config.emitter);
        }

        return emitterLayer;
    }

    public static CreateInteractionLayer(config: IInteractionLayerConfig): InteractionLayer {
        const interactionLayer = LayerFactory.Create(
            LayerType.INTERACTION_LAYER,
            config,
        ) as InteractionLayer;

        if (config.onClick) {
            interactionLayer.setOnClickHandler(config.onClick);
        }

        if (config.onOver) {
            interactionLayer.setOnOverHandler(config.onOver);
        }

        if (config.onDown) {
            interactionLayer.setOnDownHandler(config.onDown);
        }

        if (config.onOut) {
            interactionLayer.setOnOutHandler(config.onOut);
        }

        if (config.onUp) {
            interactionLayer.setOnUpHandler(config.onUp);
        }

        if (config.debug) {
            interactionLayer.enableDebug();
        }

        return interactionLayer;
    }

    public static CreateAnimatedSprite(config: IAnimatedSpriteConfig) {
        const animatedSpriteLayer = LayerFactory.Create(
            LayerType.SPRITE_ANIMATED,
            config,
        ) as SpriteAnimationLayer;

        if (config.textures) {
            animatedSpriteLayer.setTextures(config.textures);
        }

        if (config.scale) {
            animatedSpriteLayer.scale.set(config.scale, config.scale);
        }

        if (config.anchor) {
            animatedSpriteLayer.setSpriteAnchor(config.anchor.x, config.anchor.y);
        }

        return animatedSpriteLayer;
    }

    public static CreateButtonLayer(config: IButtonLayerConfig): ButtonLayer {
        const buttonLayer = LayerFactory.Create(LayerType.BUTTON, config) as ButtonLayer;

        if (config.onClick) {
            buttonLayer.setOnClickHandler(config.onClick);
        }
        buttonLayer.setTexture(config.texture);

        return buttonLayer;
    }

    public static CreateSpine(config: ISpineConfig) {
        const spineLayer = LayerFactory.Create(LayerType.SPINE, config) as SpineLayer;

        return spineLayer;
    }

    public static CreateQuad(config: IQuadConfig) {
        const quadLayer = LayerFactory.Create(LayerType.QUAD, config) as QuadLayer;

        if (config.tint !== undefined) {
            quadLayer.setTint(config.tint);
        }

        return quadLayer;
    }

    public static CreateTilingSprite(config: ITilingSpriteLayerConfig) {
        const spriteLayer = LayerFactory.Create(
            LayerType.TILING_SPRITE,
            config,
        ) as TilingSpriteLayer;

        if (config.texture) {
            spriteLayer.setTexture(config.texture);
        }
        if (config.scale) {
            spriteLayer.scale.set(config.scale, config.scale);
        }

        return spriteLayer;
    }

    public static CreatePrizeTicker(config: IPrizeTickerConfig) {
        const layer = LayerFactory.Create(LayerType.PRIZE_TICKER, config) as PrizeTickerLayer;

        layer.setWidth(config.width);
        layer.setDuration(config.duration);
        layer.setStyle(config.style);

        if (config.useDecimals !== undefined) {
            layer.setUseDecimals(config.useDecimals);
        }

        return layer;
    }

    public static CreateRoot(rootChildren: Layer[]): Layer {
        const layer = LayerFactory.Create(LayerType.CONTAINER, {
            id: 'root',
            layerChildren: rootChildren,
            layout: {
                width: '100%',
                height: '100%',
            },
        });

        layer.setAsRoot();

        return layer;
    }
}
