import { Layer } from './layer';
import { Texture } from '@pixi/core';
import { Sprite } from '@pixi/sprite';
export class SpriteLayer extends Layer {
    private sprite: Sprite;

    constructor() {
        super();
        this.sprite = new Sprite();
        this.addChild(this.sprite);
        this.cursor = 'pointer';
    }

    public getSprite(): Sprite {
        return this.sprite;
    }

    public setTexture(texture: string) {
        this.sprite.texture = Texture.from(texture);
        this.visualUpdate();
    }

    public setSpriteAnchor(x: number, y: number) {
        this.sprite.anchor.set(x, y);
    }
}
