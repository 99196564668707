import EventEmitter from 'events';
import TypedEmitter, { Arguments } from 'typed-emitter';
import { ICoord, IWinnings } from './platformInterface';
import { IBigWinConfig } from './slotMachine/features/wins/bigWinFeature';
import SlotMachineEntity from './slotMachine/slotMachineEntity';

export interface IGameEvents {
    anticipationContinue: (reelIndex: number, slotMachine: SlotMachineEntity) => void;
    anticipationStart: (reelIndex: number, slotMachine: SlotMachineEntity) => void;
    anticipationStop: () => void;
    bigWinChange: (bigWinLevel: number) => void;
    bigWinEnd: (lastLevel: number) => void;
    bigWinSkip: () => void;
    bigWinStart: (config: IBigWinConfig) => void | Promise<void>;
    canStop: () => void;
    cascadeStart: () => void;
    enterFreeSpins: () => void;
    enterMainGame: () => void;
    exitFreeSpins: () => void;
    exitMainGame: () => void;
    freeSpinExitClick: () => void;
    freeSpinStartClick: () => void;
    gameStart: () => void;
    screenLoaded: () => void;
    hideOverlay: () => void;
    hideSpots: (spots: Array<ICoord> | ICoord) => void;
    keydown: (code: string) => void;
    loadProgress: (percentage: number) => void;
    pointerdown: (target: string) => void;
    reelStop: (symbols: string[]) => void;
    reelStopping: (symbols: string[]) => void;
    resumeFreeSpins: () => void;
    resumeGame: () => void;
    resumeCascades: () => void;
    showAllPaylines: (winData: Array<IWinnings>) => void;
    showFreeSpinEnterDialog: () => void;
    showFreeSpinExitDialog: () => void;
    showOverlay: () => void;
    showPayline: (winning: IWinnings) => void;
    slotMachineFeaturesEnd: () => void;
    spinStart: () => void;
    spinStop: () => void;
    totalWin: (totalWin: number) => void;
    totalWinComplete: () => void;
    showCascadeMultiplier: () => void;
    cascadeEnd: () => void;
    preloadComplete: () => void;
    loadComplete: () => void;
}

class GameEventEmitter extends (EventEmitter as unknown as new () => TypedEmitter<IGameEvents>) {
    constructor() {
        super();
        window.addEventListener('pointerdown', (evt) => {
            this.emit('pointerdown', (<HTMLElement>evt.target)?.id);
        });

        window.addEventListener('keydown', (evt) => {
            this.emit('keydown', evt.code);
        });

        this.setMaxListeners(20);
    }

    promise<E extends keyof IGameEvents>(event: E): Promise<IGameEvents[E]> {
        return new Promise((resolve) => {
            this.once(event, ((args: any) => {
                if (args) {
                    resolve(args);
                } else {
                    resolve(null as any);
                }
            }) as any);
        });
    }

    emit<E extends keyof IGameEvents>(event: E, ...args: Arguments<IGameEvents[E]>): boolean {
        if (args.length > 0) {
            console.debug(`E: ${event}, A:`, args);
        } else {
            console.debug(`E: ${event}`);
        }

        return super.emit(event, ...args);
    }

    emitCustom(event: string, ...args: Array<unknown>): boolean {
        return super.emit(event as any, ...args);
    }
}

export const GameBus = new GameEventEmitter();

(window as any).GAME_BUS = GameBus;
