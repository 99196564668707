import DataStore from '../dataStore';
import { IFreeSpinsEndEvent } from '../engineEvents/freeSpinsEndEvent';
import FreeSpinDataTypes from '../freeSpinDataTypes';
import IParser from './IParser';

const FREE_SPINS_END_PARSER: IParser = {
    parse: (data: IFreeSpinsEndEvent) => {
        DataStore.set(FreeSpinDataTypes.IS_NEXT, false);
        console.debug('FREE SPINS END MESSAGE', data);
    },
};

export default FREE_SPINS_END_PARSER;
