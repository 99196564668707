import { Tween } from './tween';

const counters: Map<string, Tween> = new Map();

export async function Counter(
    from: number,
    to: number,
    easingFunction: (t: any) => number,
    time: number,
    callback: (t: number) => void,
    name?: string,
): Promise<void> {
    const tween: Tween = new Tween()
        .from(from)
        .to(to)
        .setEasing(easingFunction)
        .time(time)
        .onUpdate(callback as any);

    if (name) {
        counters.set(name, tween);
    }

    tween.onFinish(() => {
        if (name) {
            counters.delete(name);
        }
    });

    return tween.start();
}

export async function CancelCounter(name: string) {
    if (counters.has(name)) {
        counters.get(name)?.stop();
        counters.delete(name);
    }
}
