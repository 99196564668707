import { Layer } from '../layers/layerTypes/layer';
import { Controller } from './controller';
import DataStore from '../dataStore';
import { GameBus } from '../gameBus';
import DataTypes from '../dataTypes';
import { IPayline } from '../platformInterface';

export enum PAYLINES_CONTROLLER_ACTIONS {
    SHOW_PAYLINE = 'SHOW_PAYLINE',
    HIDE_PAYLINE = 'HIDE_PAYLINE',
    HIDE_ALL_PAYLINES = 'HIDE_ALL_PAYLINES',
}

interface IPaylineControllerConfiguration {
    reelSpacing: number;
    symbolWidth: number;
    symbolHeight: number;
    rows: number;
    columns: number;
}

export class PaylinesController extends Controller {
    private config: IPaylineControllerConfiguration;
    private paylines: any[] = [];

    constructor(config: IPaylineControllerConfiguration) {
        super();
        this.config = config;
    }

    public override onAdd(): void {
        this.createPaylines();
        GameBus.on('spinStart', () => {
            this.layer.visible = false;
        });
    }

    protected createPaylines() {
        const paylines = DataStore.get<IPayline[]>(DataTypes.PAYLINES);

        paylines.forEach((payline) => this.createPayline(payline));
    }

    protected createPayline(_payline: IPayline) {
        // const spine = new Spine(window.PIXI.Loader.shared.resources["paylines"].spineData);
        // this.layer.addChild(spine as any);
        // this.paylines.push(spine);
        // spine.visible = false;
    }

    protected hidePayline(paylineIndex: number) {
        this.paylines[paylineIndex].visible = false;
    }

    protected hideAllPaylines() {
        this.paylines.forEach((payline) => (payline.visible = false));
    }

    protected showPayline(paylineIndex: number) {
        this.layer.visible = true;
        console.debug(`Show payline number ${paylineIndex}`);
        this.paylines[paylineIndex].visible = true;
        this.paylines[paylineIndex].state.clearTracks();

        this.paylines[paylineIndex].state.setAnimation(0, `payline${paylineIndex + 1}`, false);
    }

    public override do(layer: Layer, action: string, paylineIndex: number): Promise<void> {
        return new Promise((resolve) => {
            switch (action) {
                case PAYLINES_CONTROLLER_ACTIONS.SHOW_PAYLINE:
                    this.showPayline(paylineIndex - 1);
                    break;
                case PAYLINES_CONTROLLER_ACTIONS.HIDE_ALL_PAYLINES:
                    this.hideAllPaylines();
                    break;
                case PAYLINES_CONTROLLER_ACTIONS.HIDE_PAYLINE:
                    this.hidePayline(paylineIndex - 1);
                    break;
            }
            resolve();
        });
    }
}
