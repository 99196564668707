declare const MagicUI: any;

export async function SetVersion() {
    fetch('./build.json')
        .then((response) => response.json())
        .then((json) => {
            const version = json.version;

            MagicUI.setVersion(version);
        });
}
