import { Howl } from 'howler';
import { GameBus, IGameEvents } from '../gameBus';
export namespace Audio {
    export enum IAudioEventType {
        PLAY,
        STOP,
        FADE_IN,
        FADE_OUT,
    }

    export interface IAudioEventHandler {
        eventName: keyof IGameEvents;
        handler: (...args: any) => void;
    }
    export interface IAudioEventConfig {
        eventName: keyof IGameEvents;
        type: IAudioEventType;
        soundName: string;
        time?: number;
        loop?: boolean;
    }

    export interface IAudioConfig {
        events: (IAudioEventConfig | IAudioEventHandler)[];
    }

    const sprites: Map<string, Howl> = new Map();
    const audioMap: Map<string, Howl> = new Map();
    const playingAudio: Map<string, Array<number>> = new Map();

    export async function load(howlerData: Array<any>, baseURL: string): Promise<void> {
        return new Promise(async (resolve) => {
            const spritePromises: Array<Promise<void>> = [];

            howlerData.forEach(async (audioSpriteName) => {
                const spritePromise = new Promise<void>(async (resolveSprite, reject) => {
                    const audioSpriteData = await fetch(
                        `${baseURL}/audio/${audioSpriteName}.json`,
                    ).then((response) => response.json());

                    const howl = new Howl({
                        src: audioSpriteData.urls,
                        sprite: audioSpriteData.sprite,
                    });

                    Object.keys(audioSpriteData.sprite).forEach((soundClip: string) => {
                        audioMap.set(soundClip, howl);
                        playingAudio.set(soundClip, []);
                    });

                    howl.on('load', resolveSprite);
                    howl.on('loaderror', reject);

                    sprites.set(audioSpriteName, howl);
                });

                spritePromises.push(spritePromise);
            });

            await Promise.all(spritePromises);
            resolve();
        });
    }

    export function mute(muteAudio: boolean) {
        sprites.forEach((howl) => {
            howl.mute(muteAudio);
        });
    }

    export async function fade(
        soundName: string,
        from: number,
        to: number,
        time: number,
    ): Promise<void> {
        const ids = playingAudio.get(soundName);
        const sprite = audioMap.get(soundName);

        ids?.forEach((id) => sprite?.fade(from, to, time, id));
        console.debug(`%c ♫ ${soundName}`, 'color: orange');
    }

    export function play(soundName: string, loop = false, volume = 1) {
        const sprite = audioMap.get(soundName);
        const id = sprite?.play(soundName);

        console.debug(`%c ♫ ${soundName}`, 'color: darkgreen');

        if (id !== undefined) {
            if (loop) {
                playingAudio?.get(soundName)?.push(id);
                audioMap?.get(soundName)?.loop(true, id);
            } else {
                audioMap?.get(soundName)?.loop(false, id);
            }

            audioMap?.get(soundName)?.volume(volume, id);
        }
    }

    export async function stop(soundName: string) {
        const ids = playingAudio.get(soundName);
        const sprite = audioMap.get(soundName);

        ids?.forEach((id) => sprite?.stop(id));
        playingAudio?.get(soundName)?.splice(0, playingAudio.get(soundName).length);

        console.debug(`%c ♫ ${soundName}`, 'color: red');
    }

    export function setAudioConfig(config: IAudioConfig) {
        config.events.forEach((event) => {
            if ('handler' in event) {
                GameBus.on(event.eventName, event.handler);
            } else {
                GameBus.on(event.eventName, () => {
                    switch (event.type) {
                        case IAudioEventType.PLAY:
                            Audio.play(event.soundName, event.loop);
                            break;
                        case IAudioEventType.STOP:
                            Audio.stop(event.soundName);
                            break;
                    }
                });
            }
        });
    }
}
