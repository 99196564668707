import { ACTION_ORDER, Layer } from 'game_libs/layers/layerTypes/layer';
import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { TextLayer } from 'game_libs/layers/layerTypes/textLayer';
import { Counter } from 'game_libs/utils/counter';
import { easing } from 'game_libs/utils/easing';
import { timeout } from 'game_libs/utils/timeout';
import { Tween } from 'game_libs/utils/tween';
import { TOTAL_WIN_STYLE } from '../../config/textStyles/totalWinStyle';
import { Audio } from 'game_libs/utils/audio';
import DataStore from 'game_libs/dataStore';
import DataTypes from 'game_libs/dataTypes';
import { theme } from 'game_libs/controllers/themeController';
import SlotMachineEntity from 'game_libs/slotMachine/slotMachineEntity';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Util: any;

export enum TOTAL_WIN_LAYER_ACTIONS {
    SHOW_TOTAL_WIN = 'SHOW_TOTAL_WIN',
    SHOW_LINE_WIN = 'SHOW_LINE_WIN',
    HIDE = 'HIDE',
}

export const TOTAL_WIN_LAYER = () => {
    const totalWinLayer = LayerFactory.Create(LayerType.CONTAINER, {
        id: 'totalWinLayer',
        actionOrder: ACTION_ORDER.PARALLEL,
        layout: theme.totalWin,
        events: [
            {
                event: 'spinStart',
                handler: async (layer: Layer) => {
                    await layer.do('hide');
                },
            },
        ],
        layerChildren: [
            LayerFactory.CreateText({
                id: 'totalWinText',
                style: TOTAL_WIN_STYLE,

                layout: {
                    anchor: {
                        x: 0.5,
                        y: 0.5,
                    },
                },
                actions: [
                    {
                        name: TOTAL_WIN_LAYER_ACTIONS.HIDE,
                        action: async (layer: TextLayer) => {
                            layer.alpha = 0;
                        },
                    },
                    {
                        name: TOTAL_WIN_LAYER_ACTIONS.SHOW_TOTAL_WIN,
                        action: async (layer: TextLayer, slotmachine: SlotMachineEntity, win) => {
                            if (slotmachine.hasExpandingReels) {
                                await timeout(2200);
                            }

                            const totalWin = DataStore.get<number>(DataTypes.TOTAL_WIN);
                            const totalBet = DataStore.get<number>(DataTypes.TOTAL_BET);

                            (async () => {
                                if (totalWin > totalBet) {
                                    Audio.play('Win', false, 0.2);
                                }
                                await timeout(2200);
                                Audio.play('symbolDisappears');
                                await timeout(500);
                                Audio.play('drop', false, 0.1);
                            })();

                            layer.alpha = 1;
                            layer.scale.set(0.6, 0.6);

                            Counter(
                                0,
                                win,
                                easing.linear,
                                330,
                                (t: number) => {
                                    layer.setText((t / 100).toFixed(2));
                                },
                                'totalWinTick',
                            );

                            const inTween = new Tween()
                                .from(0.6)
                                .to(1)
                                .time(130)
                                .setEasing(easing.inCubic)
                                .onUpdate((current: number) => {
                                    layer.scale.set(current as number);
                                });

                            await inTween.start();

                            await timeout(500);
                            const outTween = new Tween()
                                .from(1)
                                .to(0.6)
                                .time(130)
                                .setEasing(easing.outCubic)
                                .onUpdate((current: number) => {
                                    layer.scale.set(current as number);
                                });

                            await outTween.start();
                        },
                    },
                    {
                        name: TOTAL_WIN_LAYER_ACTIONS.SHOW_LINE_WIN,
                        action: async (layer: TextLayer, slotmachine, win) => {
                            layer.alpha = 1;
                            layer.scale.set(0.6, 0.6);
                            layer.setText(Util.toMoney(win, false));
                            const inTween = new Tween()
                                .from(0.6)
                                .to(0.8)
                                .time(130)
                                .setEasing(easing.inCubic)
                                .onUpdate((current: number) => {
                                    layer.scale.set(current as number);
                                });

                            await inTween.start();

                            await timeout(500);
                            const outTween = new Tween()
                                .from(0.8)
                                .to(0.6)
                                .time(130)
                                .setEasing(easing.outCubic)
                                .onUpdate((current: number) => {
                                    layer.scale.set(current as number);
                                });

                            await outTween.start();
                        },
                    },
                ],
            }),
        ],
    });

/////////////////
//////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////////////////////
///////
//////////////

    return totalWinLayer;
};
