import { Layer } from 'game_libs/layers/layerTypes/layer';
import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { ControllerFactory } from 'game_libs/controllers/controllerFactory';

export function OVERLAY_LAYER(): Layer {
    return LayerFactory.Create(LayerType.CONTAINER, {
        id: 'overlay',
        layout: {
            width: '100%',
            height: '100%',
        },
        events: [
            { event: 'showOverlay', handler: async (layer) => await layer.do('showOverlay') },
            { event: 'hideOverlay', handler: async (layer) => await layer.do('hideOverlay') },
        ],
        layerChildren: [
            LayerFactory.CreateQuad({
                actions: [
                    {
                        action: async (layer) => {
                            layer.alpha = 0;
                        },
                        name: 'show',
                    },
                ],
                controllers: [
                    ControllerFactory.CreateTween({
                        tweens: [
                            {
                                startAction: 'showOverlay',
                                from: { alpha: 0 },
                                to: { alpha: 0.7 },
                                time: 330,
                            },
                            {
                                startAction: 'hideOverlay',
                                from: { alpha: 0.7 },
                                to: { alpha: 0 },
                                time: 330,
                            },
                        ],
                    }),
                ],
                tint: 0x000000,
                layout: {
                    width: '100%',
                    height: '100%',
                },
            }),
        ],
    });
}
