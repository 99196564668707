import SlotMachineEntity from '../slotMachineEntity';
import SlotMachineFeature from './slotMachineFeature';
import DataStore from '../../dataStore';
import DataTypes from '../../dataTypes';
import FreeSpinDataTypes from '../../freeSpinDataTypes';
import { timeout } from '../../utils/timeout';

declare const EventManager: any;

declare const MagicUI: any;

// IdleFeature - once everything is done on slot machine
export default class IdleFeature extends SlotMachineFeature {
    public condition(): boolean {
        return true;
    }

    public async trigger(_slotMachine: SlotMachineEntity): Promise<void> {
        if (
            DataStore.get(FreeSpinDataTypes.IS_NEXT) &&
            DataStore.get<number>(FreeSpinDataTypes.CONSUMED) > 0
        ) {
            if (DataStore.get<number>(DataTypes.TOTAL_WIN) > 0) {
                await timeout(500);
            } else {
                await timeout(1000);
            }
            EventManager.emit('game:done', {});
            await timeout(1);
            MagicUI.action({ isFreeSpin: DataStore.get<boolean>(FreeSpinDataTypes.IS_ACTIVE) });
            await timeout(1);
            EventManager.emit('disable-ui', {});
        } else if (!DataStore.get(FreeSpinDataTypes.IS_NEXT)) {
            EventManager.emit('game:done', {});
        }
    }
}
