import { BigWinController } from 'game_libs/controllers/bigWinController';
import { BIG_WIN_VALUE_STYLE } from '../../config/textStyles/totalWinStyle';
import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { InteractionLayer } from 'game_libs/layers/layerTypes/interactionLayer';
import { TextLayer } from 'game_libs/layers/layerTypes/textLayer';
import { GameBus } from 'game_libs/gameBus';
import { timeout } from 'game_libs/utils/timeout';
import { easing } from 'game_libs/utils/easing';
import { ControllerFactory } from 'game_libs/controllers/controllerFactory';
import { type SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';
import { Audio } from 'game_libs/utils/audio';
import { Assets } from '@pixi/assets';
import { theme } from 'game_libs/controllers/themeController';
import { type Layer } from 'game_libs/layers/layerTypes/layer';

export enum TOTAL_WIN_LAYER_ACTIONS {
    SHOW_TOTAL_WIN = 'SHOW_TOTAL_WIN',
    SHOW_LINE_WIN = 'SHOW_LINE_WIN',
    HIDE = 'HIDE',
}

let skipped = false;

export const BIG_WIN_LAYER = () => {
    const bigWinLayer = LayerFactory.CreateText({
        id: 'bigWinValue',
        style: BIG_WIN_VALUE_STYLE,
        layout: theme.bigWin,
        controllers: [
            ControllerFactory.CreateTween({
                tweens: [
                    {
                        startAction: 'winScale',
                        custom: async (t, layer: TextLayer, scale) => {
                            t.from(layer.scale.x);
                            t.to(scale);
                            t.time(330);
                            t.setEasing(easing.inCirc);
                            t.onUpdate((current: number) => {
                                layer.scale.set(current, current);
                            });
                            GameBus.on('bigWinSkip', () => {
                                t.stop();
                            });

                            await t.start();
                        },
                    },
                    {
                        startAction: 'fadeIn',
                        from: { alpha: 0 },
                        to: { alpha: 1 },
                        time: 330,
                    },
                    {
                        startAction: 'fadeOut',
                        from: { alpha: 1 },
                        to: { alpha: 0 },
                        time: 330,
                    },
                    {
                        startAction: 'countUp',
                        id: 'bigWinCountUp',
                        custom: async (t, layer: TextLayer, target, time) => {
                            t.from(0);
                            t.to(target);
                            t.time(time);
                            t.setEasing(easing.linear);
                            t.onUpdate((current: number) => {
                                layer.setText((current / 100).toFixed(2));
                                Audio.play('Tick', false, 0.1);
                            });

                            GameBus.on('bigWinSkip', () => {
                                t.stop();
                            });

                            await t.start();
                        },
                    },
                ],
            }),
        ],
        actions: [
            {
                name: 'change',
                action: async (layer: SpineLayer, level: number) => {
                    layer.do('winScale', 1 + level * 0.5);
                },
            },
            {
                name: 'bigWinStart',
                action: async (layer: TextLayer, target, time) => {
                    layer.visible = true;
                    await Promise.all([layer.do('fadeIn'), layer.do('countUp', target, time)]);
                },
            },
            {
                name: 'bigWinEnd',
                action: async (layer: TextLayer, _level, totalWin) => {
                    layer.setText((totalWin / 100).toFixed(2));
                    await timeout(1600);
                    await layer.do('fadeOut');
                    layer.visible = false;
                },
            },
        ],
    });

    let bigWinSpine: Layer;

/////////////////
////////////////////////////////////
///////////////////////////////////////////////
///////////////////////////////////

//////////////////////////
/////////////////////////////////////////////////
/////////
///////
//////////////

    return LayerFactory.Create(LayerType.CONTAINER, {
        id: 'bigWinLayer',
        layout: {
            width: '100%',
            height: '100%',
            position: {
                x: 0,
                y: 0,
            },
        },
        controllers: [new BigWinController()],
        layerChildren: [
            (bigWinSpine = LayerFactory.CreateSpine({
                spineData: 'Pop_Ups_All',
                actions: [
                    {
                        name: 'show',
                        action: async (layer: SpineLayer) => {
                            layer.visible = false;
                        },
                    },
                    {
                        name: 'bigWinStart',
                        action: () => {
                            skipped = false;
                        },
                    },
                    {
                        name: 'change',
                        action: async (layer: SpineLayer, level: number, name: string) => {
                            layer.visible = true;
                            let anim;

                            const { spineData } = Assets.get('Pop_Ups_All.json');

                            const isThereTotalAnimation = spineData.animations.find(
                                (a: { name: string }) => a.name === 'total',
                            );

                            if (name === 'total' && isThereTotalAnimation) {
                                (async () => {
                                    !skipped && (await layer.play('start_total'));
                                    !skipped && layer.play('idle_total', true);
                                })();
                            } else if (name === 'big' && !skipped) {
                                (async () => {
                                    !skipped && Audio.play('winBig');
                                    if (isThereTotalAnimation) {
                                        !skipped && (await layer.play('ending_total'));
                                    }
                                    !skipped && (await layer.play('start_big'));
                                    anim = !skipped && layer.play('idle_big', true);
                                })();
                            } else if (name === 'mega' && !skipped) {
                                (async () => {
                                    !skipped && Audio.play('winBig2');
                                    !skipped && (await layer.play('ending_big'));
                                    !skipped && (await layer.play('start_mega'));
                                    anim = !skipped && layer.play('idle_mega', true);
                                })();
                            } else if (name === 'epic') {
                                (async () => {
                                    !skipped && Audio.play('winMega');
                                    !skipped && (await layer.play('ending_mega'));
                                    !skipped && (await layer.play('start_epic'));
                                    anim = !skipped && layer.play('idle_epic', true);
                                })();
                            }

                            await Promise.race([
                                anim,
                                new Promise<void>((resolve) => {
                                    GameBus.once('bigWinSkip', resolve);
                                }),
                            ]);
                        },
                    },
                    {
                        name: 'bigWinSkip',
                        action: async () => {
                            skipped = true;

                            Audio.fade('winMega', 1, 0, 1000);
                            Audio.fade('winBig', 1, 0, 1000);
                            Audio.fade('winBig2', 1, 0, 1000);
                            await timeout(1000);
                            Audio.stop('winMega');
                            Audio.stop('winBig');
                            Audio.stop('winBig2');
                        },
                    },
                    {
                        name: 'bigWinEnd',
                        action: async (layer: SpineLayer, name: string) => {
                            await timeout(2000);

                            const { spineData } = Assets.get('Pop_Ups_All.json');

                            const isThereTotalAnimation = spineData.animations.find(
                                (a: { name: string }) => a.name === 'total',
                            );

                            if (name === 'total' && isThereTotalAnimation) {
                                await layer.play(`ending_total`);
                            } else if (name === 'big' && !skipped) {
                                Audio.play('winBigEnd');
                                await layer.play(`ending_big`);
                            } else if (name === 'mega' && !skipped) {
                                Audio.play('winBigEnd');
                                await layer.play(`ending_mega`);
                            } else if (name === 'epic' && !skipped) {
                                Audio.play('winBigEnd');
                                await layer.play(`ending_epic`);
                            }

                            layer.visible = false;
                        },
                    },
                ],
            })),
            LayerFactory.CreateInteractionLayer({
                id: 'bigWinSkipArea',
                debug: false,
                layout: {
                    alpha: 1,
                    width: '200%',
                    height: '200%',
                    anchor: {
                        x: 0.5,
                        y: 0.5,
                    },
                },
                onClick: () => {
                    GameBus.emit('bigWinSkip');
                },
                actions: [
                    {
                        name: 'show',
                        action: async (layer: InteractionLayer) => {
                            layer.disable();
                            layer.visible = false;
                        },
                    },
                    {
                        name: 'bigWinStart',
                        action: async (layer: InteractionLayer) => {
                            layer.enable();
                            layer.visible = true;
                        },
                    },
                    {
                        name: 'bigWinSkip',
                        action: async (layer: InteractionLayer) => {
                            layer.disable();
                            layer.visible = false;
                        },
                    },
                    {
                        name: 'bigWinEnd',
                        action: async (layer: InteractionLayer) => {
                            layer.disable();
                            layer.visible = false;
                        },
                    },
                ],
            }),

            bigWinLayer,
        ],
    });
};
