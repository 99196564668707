import { timeout } from '../../utils/timeout';
import SlotMachineFeature, { IFeatureConfig } from './slotMachineFeature';
import SlotMachineEntity from '../slotMachineEntity';
import DataStore from '../../dataStore';
import DataTypes from '../../dataTypes';

interface ISpinFeatureConfig extends IFeatureConfig {
    time: number;
}

export default class SpinFeature extends SlotMachineFeature {
    private time: number;

    constructor(config: ISpinFeatureConfig) {
        super(config);

        this.time = config.time;
    }

    public condition(): boolean {
        return super.checkOverrideCondition();
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        console.debug('SPIN FEATURE');
        for (let i = 0; i < slotMachine.reelCount; i++) {
            const reel = slotMachine.getReel(i);

            reel.setSpeedFactor(1);
        }

        await timeout(this.time);
        while (!DataStore.get(DataTypes.CAN_STOP)) {
            await timeout(16);
        }

        console.debug('SPIN FEATURE END');
    }
}
