import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import { IPrizeTableID, ISymbolPrize } from '../engineEvents/configEvent';

function fillSymbolTable(
    symbol: string,
    prizes: ISymbolPrize,
    prizeTableScaler: number,
    bet: number,
) {
    const element = document.getElementById(`pay-table-symbol-${symbol}`);

    if (element) {
        const list = document.createElement('ul');

        element.innerHTML = '';
        element.appendChild(list);
        for (const occurrences in prizes) {
            const scaler = symbol === 'SC' ? 1 : prizeTableScaler;
            const prize = Math.floor(((prizes[occurrences] / scaler) * (bet / 100)) * 100) / 100;

            if (prize <= 0) {
                continue;
            }
            const listEntry = document.createElement('li');
            const occurrencesElement = document.createElement('span');

            occurrencesElement.classList.add('left');
            const valueElement = document.createElement('span');

            valueElement.classList.add('right');
            listEntry.appendChild(occurrencesElement);
            listEntry.appendChild(valueElement);
            list.appendChild(listEntry);
            occurrencesElement.innerText = `${occurrences}x `;

            valueElement.innerText = Util.getCurrencySymbol() + (prize);
        }
    }
}

export function FillPaytable(bet: number) {
    const prizeTable = DataStore.get<IPrizeTableID>(DataTypes.PRIZE_TABLE);
    const prizeTableScaler = DataStore.get<number>(DataTypes.PRIZE_TABLE_SCALER);

    for (const symbol in prizeTable) {
        fillSymbolTable(symbol, prizeTable[symbol], prizeTableScaler, bet);
    }
}
