import { EventEmitter } from 'events';

const DEBUG = false;

export default class DataStore {
    private static data: Map<string, any> = new Map();
    private static setEmitter = new EventEmitter();
    private static clearEmitter = new EventEmitter();
    private static addEmitter = new EventEmitter();

    public static set(dataRef: string, value: any) {
        if (!this.data.has(dataRef)) {
            this.addEmitter.emit('add', dataRef);
        }
        this.data.set(dataRef, value);
        if (DEBUG) {
            console.log(`%c${dataRef}`, 'color: red');
            console.log(value);
        }

        this.setEmitter.emit(dataRef, value);
    }

    public static onSet(dataRef: string, listener: () => void) {
        this.setEmitter.on(dataRef, listener);
    }

    public static onAdd(listener: (dataRef: any) => void) {
        this.addEmitter.on('add', listener);
    }

    public static get<T>(dataRef: string): T {
        return this.data.get(dataRef) as T;
    }

    public static clear(dataRef: string) {
        this.data.delete(dataRef);
        this.clearEmitter.emit(dataRef);
    }

    public static list() {
        return Array.from(this.data.keys()).sort();
    }
}
