import { Controller } from 'game_libs/controllers/controller';
import { GameBus } from 'game_libs/gameBus';
import { Layer } from 'game_libs/layers/layerTypes/layer';
import { LayerFactory } from 'game_libs/layers/layerTypes/layerFactory';
import { ICoord, IOverride } from 'game_libs/platformInterface';
import SlotMachineEntity from 'game_libs/slotMachine/slotMachineEntity';
import { timeout } from 'game_libs/utils/timeout';
import { Container, DisplayObject } from '@pixi/display';
import anime from 'animejs';
import { Audio } from 'game_libs/utils/audio';

export enum SYMBOL_CONTROLLER_ACTIONS {
    ANIMATE_ALL_WINNING_SYMBOLS = 'ANIMATE_ALL_WINNING_SYMBOLS',
    HIDE_ANIMATIONS = 'HIDE_ANIMATIONS',
    ANIMATE_WINNING_LINE = 'ANIMATE_WINNING_LINE',
}

export interface ISymbolAnimationControllerConfig {
    reelSpacing: number;
    symbolWidth: number;
    symbolHeight: number;
    symbolFactory: (symbolID: string) => Container;
    playWildPlaceAnimation: (
        layer: Layer,
        slotMachine: SlotMachineEntity,
        symbol: DisplayObject,
        x: number,
        y: number,
        coord: ICoord,
    ) => Promise<void>;
}

export class PlaceWildsController extends Controller {
    private config: ISymbolAnimationControllerConfig;
    private animations: DisplayObject[] = [];

    constructor(config: ISymbolAnimationControllerConfig) {
        super();
        this.config = config;
    }

    public onAdd(): void {
        this.layer.addAction({
            name: 'placeWilds',
            action: this.placeWilds.bind(this),
        });

        GameBus.on('spinStart', () => {
            this.hide();
        });
    }

    private async placeWilds(layer: Layer, slotMachine: SlotMachineEntity, wilds: IOverride[]) {
        this.layer.visible = true;

        Audio.play(`PlaceWilds`);

        layer.layerParent.do('placedWildsLayer');
        await timeout(600);

        for (const wild of wilds) {
            const wildSymbol = this.config.symbolFactory(wild.new);

            wildSymbol.visible = false;
            layer.addChild(wildSymbol);
            this.animations.push(wildSymbol);

            const x = wild.inCoord.reel * this.config.reelSpacing + this.config.symbolWidth / 2;
            const y = wild.inCoord.row * this.config.symbolHeight + this.config.symbolHeight / 2;
            const distance = Math.sqrt((x - 665) ** 2 + (y - -218) ** 2);
            let boltSize = 'S';

            const rotation = Math.atan2(y - -218, x - 665) - Math.PI / 2;

            if (distance > 450) {
                boltSize = 'M';
            }

            if (distance > 650) {
                boltSize = 'L';
            }

            if (distance > 1000) {
                boltSize = 'XL';
            }

            const bolt = LayerFactory.CreateSpine({
                spineData: 'placedWildsActive',
                layout: {
                    scale: {
                        x: 1,
                        y: 1,
                    },
                },
            });

            bolt.rotation = rotation;

            bolt.play(`bolt-${boltSize}`);
            bolt.position.set(x, y);
            layer.addChild(bolt);
            this.animations.push(bolt);

            wildSymbol.visible = true;
            anime({
                targets: slotMachine.getSymbol(wild.inCoord.reel, wild.inCoord.row),
                alpha: 0,
                duration: 330,
            });
            await this.config.playWildPlaceAnimation(
                this.layer,
                slotMachine,
                wildSymbol,
                x,
                y,
                wild.inCoord,
            );
            slotMachine.setSymbol(wild.new, wild.inCoord.reel, wild.inCoord.row);
            wildSymbol.visible = false;
        }
        await timeout(500);
    }

    public async hide() {
        this.animations.forEach((animation) => this.layer.removeChild(animation));
        this.animations = [];
        this.layer.visible = false;
    }
}
