import { GameBus } from '../gameBus';
import { Controller } from './controller';
import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import { timeout } from '../utils/timeout';
import SlotDataTypes from '../parsing/dataTypes/slotDataTypes';

export enum BIG_WIN_CONTROLLER_ACTIONS {
    ENTER_STAGE = 'ENTER_STAGE',
    EXIT_STAGE = 'EXIT_STAGE',
}

export class BigWinController extends Controller {
    private skipped = true;

    public async skip() {
        return new Promise<void>((resolve) => {
            GameBus.once('bigWinSkip', () => {
                resolve();
                this.layer.doParallel('bigWinSkip');
            });
        });
    }

    public override onAdd(): void {
        GameBus.on('bigWinStart', async (config) => {
            GameBus.emit('showOverlay');
            let levels = 0;
            let lastLevel;

            this.skipped = false;
            const skipPromise = this.skip();

            const totalWin = DataStore.get<number>(DataTypes.TOTAL_WIN);
            let time = 0;

            for (const level of config.levels) {
                if (totalWin >= DataStore.get<number>(DataTypes.TOTAL_BET) * level.multiplier) {
                    time += level.time;
                    levels++;
                }
            }

            const interval = totalWin / levels;

            for (let i = 0; i < config.levels.length; i++) {
                if (
                    totalWin >=
                    DataStore.get<number>(DataTypes.TOTAL_BET) * config.levels[i].multiplier
                ) {
                    if (!this.skipped) {
                        lastLevel = i;
                    }
                }
            }

            DataStore.set(SlotDataTypes.BIG_WIN_TARGET, lastLevel);
            this.layer.doParallel('bigWinStart', totalWin, time);
            for (let i = 0; i < levels; i++) {
                GameBus.emit('bigWinChange', i);
                console.debug('Big Win: CHANGE', i);
                if (this.skipped) {
                    break;
                } else {
                    await Promise.race([
                        skipPromise,
                        this.layer.doParallel(
                            'change',
                            i,
                            config.levels[i].name,
                            interval * i,
                            interval * i + interval,
                            i === levels - 1,
                        ),
                    ]);
                    await Promise.race([skipPromise, timeout(config.levels[i].time)]);
                }
            }

            console.debug('Big Win: END');
            await this.layer.doParallel('bigWinEnd', config.levels[lastLevel].name, totalWin);
            GameBus.emit('bigWinEnd', lastLevel);
            GameBus.emit('hideOverlay');
        });
    }
}
