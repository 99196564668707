import SlotMachineEntity from '../../slotMachineEntity';
import MetaFeature from './metaFeature';

// OrFeature - only one feature will works, the one that meets the condition first
export default class OrFeature extends MetaFeature {
    public condition(): boolean {
        return true;
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        for (let i = 0; i < this.features.length; i++) {
            const feature = this.features[i];

            if (feature.condition()) {
                if (feature.hasOverrideCondition()) {
                    if (feature.checkOverrideCondition()) {
                        return feature.trigger(slotMachine);
                    }
                } else if (feature.condition()) {
                    return feature.trigger(slotMachine);
                }
            }
        }

        return Promise.resolve();
    }
}
