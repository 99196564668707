import DataStore from 'game_libs/dataStore';
import SlotMachineFeature from 'game_libs/slotMachine/features/slotMachineFeature';
import { AwaitReplyCustom } from 'game_libs/utils/awaitReply';
import { CUSTOM_DATA_TYPES } from '../parsing/customDataTypes';

export default class RetriggerPopupFeature extends SlotMachineFeature {
    public condition(): boolean {
        const retriggeredFreespinCount = DataStore.get<number>(
            CUSTOM_DATA_TYPES.RETRIGGERED_FREESPIN_COUNT,
        );

        return retriggeredFreespinCount > 0;
    }

    public async trigger(): Promise<void> {
        await AwaitReplyCustom('showFreeSpinRetriggerDialog', 'freeSpinRetriggerClick');
    }
}
