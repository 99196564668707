import SlotMachineEntity from '../slotMachineEntity';
import MetaFeature from './metaFeatures/metaFeature';

/**
 * SequenceFeature
 * all features work if meet condition one after another
 * * if one of the features fails, the sequence stops
 */
//
export default class SequenceFeature extends MetaFeature {
    public condition(): boolean {
        let condition = true;

        this.features.forEach((feature) => {
            if (!feature.condition()) {
                condition = false;
            }
        });

        return condition;
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        for (let i = 0; i < this.features.length; i++) {
            await this.features[i].trigger(slotMachine);
        }
    }
}
