import { TextStyle } from '@pixi/text';

export const TOTAL_WIN_STYLE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.5,
    dropShadowBlur: 16,
    dropShadowDistance: 5,
    dropShadowColor: '#7665bc',
    fill: ['#d3832a', '#f9c526', '#fff08b', '#eca827'],
    fillGradientStops: [0.5, 0.55, 0.65, 0.8],
    fontFamily: 'AlfaSlabOne',
    fontSize: 250,
    letterSpacing: 8,
    lineJoin: 'round',
    stroke: '#2c2698',
    strokeThickness: 20,
    padding: 20,
};

export const BIG_WIN_STYLE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.5,
    dropShadowBlur: 16,
    dropShadowDistance: 0,
    fill: ['#FDF53E', '#FEEF39', '#E4A022', '#BE5F06'],
    fontFamily: 'AlfaSlabOne',
    fontSize: 150,
    letterSpacing: 6,
    lineJoin: 'round',
    stroke: '#000000',
    strokeThickness: 10,
    padding: 20,
};

export const BIG_WIN_VALUE_STYLE: Partial<TextStyle> = {
    align: 'center',
    dropShadow: true,
    dropShadowAngle: 1.5,
    dropShadowBlur: 16,
    dropShadowDistance: 0,
    fill: ['#FDF53E', '#FEEF39', '#E4A022', '#BE5F06'],
    fontFamily: 'AlfaSlabOne',
    fontSize: 120,
    letterSpacing: 6,
    lineJoin: 'round',
    stroke: '#000000',
    strokeThickness: 10,
    padding: 20,
};
