import { Assets } from '@pixi/assets';
import { ILayoutConfig } from 'game_libs/layers/layerTypes/layerFactory';

export type ThemeChangeCallback = (theme: Theme) => void;

class ThemeController {
    themeData: Theme;
    private subscriptions: ThemeChangeCallback[] = [];

    async init() {
        this.themeData = Assets.get('theme');
    }

    update(theme?: Theme) {
        if (theme) {
            this.themeData = theme;
        }

        this.subscriptions.forEach((callback) => callback(this.themeData));
    }

    onchange(callback: ThemeChangeCallback) {
        this.subscriptions.push(callback);
    }

    get bg(): ILayoutConfig & SpineConfig {
        return this.themeData.bg;
    }

    get bgFreeSpins(): SpineConfig {
        return this.themeData.bgFreeSpins;
    }

    get logo(): ILayoutConfig & {
        texture: string;
    } {
        return this.themeData.logo;
    }

    get anticipation(): ILayoutConfig & { horizontalOffset: number } {
        return this.themeData.anticipation;
    }

    get bgScaling(): Dimensions {
        return this.themeData.bgScaling;
    }

    get slotMachineLayer(): ILayoutConfig {
        return this.themeData.slotMachineLayer;
    }

    get slotMachineLayerMask(): SlotMachineLayerMask {
        return this.themeData.slotMachineLayerMask;
    }

    get placedWilds(): ILayoutConfig | undefined {
        return this.themeData.placedWilds;
    }

    get payLines(): ILayoutConfig | undefined {
        return this.themeData.payLines;
    }

    get symbolSize(): Dimensions {
        return this.themeData.symbolSize;
    }

    get reelSpacing(): number {
        return this.themeData.reelSpacing;
    }

    get symbolsSpacing(): number {
        return this.themeData.symbolsSpacing;
    }

    get totalWin(): ILayoutConfig {
        return this.themeData.totalWin;
    }

    get bigWin(): ILayoutConfig {
        return this.themeData.bigWin;
    }

    get bgColor(): number {
        return this.themeData.bgColor;
    }

    get symbolScaling(): number {
        return this.themeData.symbolScaling;
    }

    get popupButtonOffset(): PopupButtonOffset {
        return (
            this.themeData.popupButtonOffset ?? {
                startFS: -145,
                retriggerFS: -145,
                stopFSButton: -100,
            }
        );
    }
}

export const theme = new ThemeController();

export type Theme = {
    bgColor: number;
    bgScaling: Dimensions;
    symbolSize: Dimensions;

    symbolsSpacing: number;
    reelSpacing: number;
    symbolScaling: number;

    bg: SpineConfig;
    bgFreeSpins: SpineConfig;

    anticipation: ILayoutConfig & { horizontalOffset: number };
    slotMachineLayer: ILayoutConfig;
    placedWilds?: ILayoutConfig;
    totalWin: ILayoutConfig;
    bigWin: ILayoutConfig;

    logo: SpriteConfig;
    slotMachineLayerMask: SlotMachineLayerMask;
    popupButtonOffset: PopupButtonOffset;
    payLines: ILayoutConfig;
};

type SpineConfig = ILayoutConfig & {
    spineData: string;
};

type SpriteConfig = ILayoutConfig & {
    texture: string;
};

type SlotMachineLayerMask = {
    texture: string;
    position: Position;
    scale?: Position;
    size?: Dimensions;
    visible?: boolean;
};

type Position = {
    x: number;
    y: number;
};

type Dimensions = {
    w: number;
    h: number;
};

export type PopupButtonOffset = {
    startFS: number;
    retriggerFS: number;
    stopFSButton: number;
};
