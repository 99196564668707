import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import IAvailableActionsEvent from '../engineEvents/availableActionsEvent';
import IParser from './IParser';

const AVAILABLE_ACTIONS_PARSER: IParser = {
    parse: (data: IAvailableActionsEvent) => {
        const fsNext = data.state.startsWith('FS');

        DataStore.set(DataTypes.STATE, data.state);
        if (fsNext) {
            DataStore.set(DataTypes.SPECIAL_SYMBOL, data.state.replace('FS_', ''));
        }
    },
};

export default AVAILABLE_ACTIONS_PARSER;
