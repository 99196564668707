import { GameBus, IGameEvents } from '../gameBus';

export async function AwaitReply(
    eventSource: keyof IGameEvents,
    eventTarget: keyof IGameEvents,
    ...values: any[]
): Promise<void> {
    return new Promise((resolve) => {
        GameBus.once(eventTarget, () => {
            resolve();
        });

        GameBus.emit(eventSource as any, ...values);
    });
}

export async function AwaitReplyCustom(
    eventSource: string,
    eventTarget: string,
    ...values: any[]
): Promise<void> {
    return new Promise((resolve) => {
        GameBus.once(eventTarget as any, () => {
            resolve();
        });

        GameBus.emit(eventSource as any, ...values);
    });
}
