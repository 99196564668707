import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import IShowEvent from '../engineEvents/showEvent';
import FreeSpinDataTypes from '../freeSpinDataTypes';
import IParser, { IParserConfiguration } from './IParser';

let reelOffset = 0;

export interface IShowParserConfiguration extends IParserConfiguration {
    reelOffset: number;
}

const SHOW_PARSER: IParser = {
    configure(configuration: IShowParserConfiguration) {
        reelOffset = configuration.reelOffset;
    },
    parse: (data: IShowEvent) => {
        if (data.winnings) {
            DataStore.set(DataTypes.WINNINGS_FROM_RECONNECTION, true);
            DataStore.set(DataTypes.WINNINGS, data.winnings);
            DataStore.set(
                DataTypes.TOTAL_WIN,
                data.winnings.reduce<number>((prev, current) => prev + current.winnings, 0),
            );
        }

        const fsNext = data.state.startsWith('FS');

        DataStore.set(DataTypes.STATE, data.state);
        DataStore.set(FreeSpinDataTypes.IS_NEXT, fsNext);
        if (data.lastState.startsWith('FS_')) {
            DataStore.set(DataTypes.RESUME_GAME, true);
        }

        DataStore.set(DataTypes.TOTAL_BET, data.bet);

        if (fsNext) {
            DataStore.set(DataTypes.SPECIAL_SYMBOL, data.state.replace('FS_', ''));
        }

        if (data.freeSpinsStates && data.freeSpinsStates[data.state]) {
            DataStore.set(
                FreeSpinDataTypes.REMAINING,
                data.freeSpinsStates[data.state].freeSpinRemaining,
            );
            DataStore.set(
                FreeSpinDataTypes.CONSUMED,
                data.freeSpinsStates[data.state].freeSpinsConsumed,
            );
            DataStore.set(
                FreeSpinDataTypes.TOTAL_WIN,
                data.freeSpinsStates[data.state].freeSpinsAccumulatedWinnings,
            );
        }

        const panel: string[][] = [];
        const enginePanel = data.panel || data.initialPanel;

        for (let i = reelOffset; i < enginePanel.reels.length; i++) {
            panel.push(enginePanel.reels[i].symbols);
        }

        DataStore.set(DataTypes.PANEL, panel);
    },
};

export default SHOW_PARSER;
