import DataStore from '../dataStore';
import IFreeSpinsStartEvent from '../engineEvents/freeSpinsStartEvent';
import FreeSpinDataTypes from '../freeSpinDataTypes';
import IParser from './IParser';

const FREE_SPINS_START_PARSER: IParser = {
    parse: (data: IFreeSpinsStartEvent) => {
        DataStore.set(FreeSpinDataTypes.IS_NEXT, true);
        DataStore.set(FreeSpinDataTypes.REMAINING, data.context.freeSpinRemaining);
        DataStore.set(FreeSpinDataTypes.CONSUMED, 0);
        DataStore.set(FreeSpinDataTypes.TOTAL_WIN, 0);
    },
};

export default FREE_SPINS_START_PARSER;
