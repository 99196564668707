import DataStore from 'game_libs/dataStore';
import SlotMachineFeature from 'game_libs/slotMachine/features/slotMachineFeature';
import { CUSTOM_DATA_TYPES } from '../parsing/customDataTypes';
import DataTypes from 'game_libs/dataTypes';
import { Layer } from 'game_libs/layers/layerTypes/layer';
import { IOverride } from 'game_libs/platformInterface';
import SlotMachineEntity from 'game_libs/slotMachine/slotMachineEntity';
import { features } from 'game_libs/controllers/featureController';

export default class PlaceWildsFeature extends SlotMachineFeature {
    public condition(): boolean {
        const overrides = DataStore.get<IOverride[]>(CUSTOM_DATA_TYPES.PLACED_WILDS);

        if (!features.placedWilds) {
            return true;
        }

        return overrides?.length > 0;
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        if (features.placedWilds) {
            console.warn('PLACED WILDS DETECTED');
            const placedWildsLayer = DataStore.get<Layer>(DataTypes.ROOT_LAYER).findRecursive(
                'placedWildsLayer',
            );

            await placedWildsLayer.do(
                'placeWilds',
                slotMachine,
                DataStore.get<IOverride[]>(CUSTOM_DATA_TYPES.PLACED_WILDS),
            );
        }
    }
}
