import { StateMachine } from 'game_libs/state/stateMachine';
import { GameBus } from 'game_libs/gameBus';
import DataStore from 'game_libs/dataStore';
import FreeSpinDataTypes from 'game_libs/freeSpinDataTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const MagicUI: any;

export enum GAME_STATES {
    NULL = 'NULL',
    MAIN = 'MAIN',
    FREE_SPINS = 'FREE_SPINS',
}

export const GameStateMachine = new StateMachine({
    initialState: GAME_STATES.NULL,
    transitions: [
        {
            from: GAME_STATES.NULL,
            to: GAME_STATES.MAIN,
            events: 'enterMainGame',
        },
        {
            from: GAME_STATES.NULL,
            to: GAME_STATES.FREE_SPINS,
            events: 'resumeFreeSpins',
        },
        {
            from: GAME_STATES.MAIN,
            to: GAME_STATES.FREE_SPINS,
            events: ['exitMainGame', 'enterFreeSpins'],
        },
        {
            from: GAME_STATES.FREE_SPINS,
            to: GAME_STATES.MAIN,
            events: ['exitFreeSpins', 'enterMainGame'],
        },
    ],
});

GameBus.on('slotMachineFeaturesEnd', () => {
    const fsActive = DataStore.get(FreeSpinDataTypes.IS_ACTIVE);
    const fsNext = DataStore.get(FreeSpinDataTypes.IS_NEXT);

    if (fsActive || fsNext) {
        const consumedFS = DataStore.get<number>(FreeSpinDataTypes.CONSUMED);
        const remainingFS = DataStore.get<number>(FreeSpinDataTypes.REMAINING);
        const totalWin = DataStore.get(FreeSpinDataTypes.TOTAL_WIN);

        MagicUI.updateFreeSpins(consumedFS + 1, consumedFS + remainingFS);
        MagicUI.updateFreeSpinWinnings(totalWin);
    }

    if (fsNext && !fsActive) {
        GameStateMachine.enter(GAME_STATES.FREE_SPINS);
        DataStore.set(FreeSpinDataTypes.IS_ACTIVE, true);
    } else if (fsActive && !fsNext) {
        GameStateMachine.enter(GAME_STATES.MAIN);
        DataStore.set(FreeSpinDataTypes.IS_ACTIVE, false);
    }
});
