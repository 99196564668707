import { Text, TextStyle } from '@pixi/text';
import { Layer } from './layer';
import anime from 'animejs';
import { Container } from '@pixi/display';

declare const Util: any;

export class PrizeTickerLayer extends Layer {
    private characters: Text[];
    private style: Partial<TextStyle> = {};
    private characterWidth: number;
    private duration: number;
    private useDecimals = false;
    private container = new Container();

    constructor() {
        super();
        this.addChild(this.container);
    }

    public setStyle(style: Partial<TextStyle>) {
        this.style = style;
    }

    public setDuration(duration: number) {
        this.duration = duration;
    }

    public setWidth(width: number) {
        this.characterWidth = width;
    }

    public setUseDecimals(useDecimals: boolean) {
        this.useDecimals = useDecimals;
    }

    public setValue(value: number) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let targetString = Util.getCurrencySymbol() + (value / 100).toFixed(2);

        if (!this.useDecimals) {
            targetString = targetString.substring(0, targetString.length - 3);
        }
        let rollingX = 0;

        this.characters.forEach((character) => {
            character.text = '';
        });
        for (let i = 0; i < targetString.length; i++) {
            this.characters[i].text = targetString[i];
            if (targetString[i] === '.') {
                rollingX += this.characterWidth * 0.5;
                this.characters[i].x = rollingX;
            } else {
                rollingX += this.characterWidth;
                this.characters[i].x = rollingX - this.characters[i].width / 2;
            }
        }

        this.container.x = -rollingX / 2;
    }

    public setTarget(target: number, from = 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const targetString = Util.getCurrencySymbol() + (target / 100).toFixed(2);
        const fromString = Util.getCurrencySymbol() + (from / 100).toFixed(2);

        this.container.removeChildren();

        this.characters = [];
        let length;

        if (targetString.length > fromString.length) {
            length = targetString.length;
        } else {
            length = fromString.length;
        }
        for (let i = 0; i < length; i++) {
            const text = new Text('', this.style);

            this.characters.push(text);
            this.container.addChild(text);
        }

        const prize = { prize: from };

        this.setValue(prize.prize);
        anime({
            targets: [prize],
            prize: target,
            duration: this.duration,
            easing: 'easeOutSine',
            update: (anim) => {
                const prizeValue = (anim.animatables[0].target as any).prize;

                this.setValue(prizeValue);
            },
            complete: () => {
                this.setValue(target);
            },
        });
    }
}
