import { Layer } from './layer';
import { TilingSprite } from '@pixi/sprite-tiling';
import { Texture } from '@pixi/core';
import DataStore from '../../dataStore';
import DataTypes from '../../dataTypes';
export class TilingSpriteLayer extends Layer {
    private sprite: TilingSprite;

    public setTexture(texture: string) {
        if (this.sprite) {
            this.removeChild(this.sprite);
        }
        this.sprite = new TilingSprite(
            DataStore.get<Record<string, Texture>>(DataTypes.TEXTURES)[texture],
            2048,
            2048,
        );
        // this.sprite.tileScale.set(10, 10);
        this.addChild(this.sprite);
        this.visualUpdate();
    }
}
