import { PAYLINES_CONTROLLER_ACTIONS } from '../../../controllers/paylinesController';
import { SYMBOL_CONTROLLER_ACTIONS } from '../../../controllers/symbolWinAnimationController';
import DataStore from '../../../dataStore';
import DataTypes from '../../../dataTypes';
import { GameBus } from '../../../gameBus';

import { Layer } from '../../../layers/layerTypes/layer';
import { IWinnings, WINNING_TYPE } from '../../../platformInterface';

import SlotMachineEntity from '../../slotMachineEntity';
import SlotMachineFeature, { IFeatureConfig } from '../slotMachineFeature';

declare const EventManager: any;

export enum TOTAL_WIN_ACTIONS {
    SHOW_TOTAL_WIN = 'SHOW_TOTAL_WIN',
    HIDE = 'HIDE',
    SHOW_LINE_WIN = 'SHOW_LINE_WIN',
}

export interface ITotalWinFeatureConfig extends IFeatureConfig {
    showSlotMachineSymbolAfter?: boolean;
    showPaylines?: boolean;
    showTotalWin?: boolean;
}

export default class TotalWinFeature extends SlotMachineFeature {
    private showSlotMachineSymbolAfter: boolean;
    private showPaylines: boolean;
    private showTotalWin: boolean | undefined;
    private ended: boolean;

    constructor(config: ITotalWinFeatureConfig) {
        super(config);

        this.showPaylines = config.showPaylines === undefined ? true : config.showPaylines;
        this.showSlotMachineSymbolAfter =
            config.showSlotMachineSymbolAfter !== undefined
                ? config.showSlotMachineSymbolAfter
                : true;
        this.showTotalWin = config.showTotalWin;
    }

    public condition(): boolean {
        return (
            DataStore.get<number>(DataTypes.TOTAL_WIN) > 0 &&
            !DataStore.get(DataTypes.SPIN_IN_PROGRESS)
        );
    }

    private async end() {
        this.ended = false;

        return new Promise<void>(async (resolve) => {
            GameBus.once('spinStart', () => {
                this.ended = true;
                resolve();
            });
        });
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        console.debug('TOTAL WIN FEATURE');
        const end = this.end();
        const win = DataStore.get<number>(DataTypes.TOTAL_WIN);
        const winData = DataStore.get<Array<IWinnings>>(DataTypes.WINNINGS);

        const root = DataStore.get(DataTypes.ROOT_LAYER) as Layer;
        const totalWinLayer = root.findRecursive('totalWinLayer');
        const slotMachineLayer = root.findRecursive('slotMachine');
        const payLinesLayer = root.findRecursive('paylinesLayer');

        if (this.ended) {
            return;
        }

        const paylines: Promise<void>[] = [];

        if (this.showTotalWin !== false) {
            GameBus.emit('totalWin', win);
            EventManager.emit('game:winnings', { amount: win });
        }

        if (this.showPaylines && payLinesLayer) {
            GameBus.emit('showAllPaylines', winData);
            winData.forEach((winning) => {
                if (winning.winningType === WINNING_TYPE.LINE) {
                    paylines.push(
                        payLinesLayer.do(
                            PAYLINES_CONTROLLER_ACTIONS.SHOW_PAYLINE,
                            parseInt(winning.id, 10),
                        ),
                    );
                }
            });
        }

        await Promise.race([
            end,
            Promise.all([
                this.showTotalWin !== false &&
                    totalWinLayer.do(TOTAL_WIN_ACTIONS.SHOW_TOTAL_WIN, slotMachine, win),
                slotMachineLayer?.do(
                    SYMBOL_CONTROLLER_ACTIONS.ANIMATE_ALL_WINNING_SYMBOLS,
                    slotMachine,
                    win,
                    winData,
                ),
                Promise.all(paylines),
            ]),
        ]);

        if (this.ended) {
            totalWinLayer.do(TOTAL_WIN_ACTIONS.HIDE);
            if (this.showPaylines) {
                payLinesLayer?.do(PAYLINES_CONTROLLER_ACTIONS.HIDE_ALL_PAYLINES);
            }

            return;
        }

        await Promise.race([
            end,
            await Promise.all([
                totalWinLayer.do(TOTAL_WIN_ACTIONS.HIDE),
                this.showPaylines &&
                    payLinesLayer?.do(PAYLINES_CONTROLLER_ACTIONS.HIDE_ALL_PAYLINES),
            ]),
        ]);
    }
}
