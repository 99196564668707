import SlotMachineEntity from '../slotMachineEntity';

export interface IFeatureConfig {
    condition?: () => boolean;
}
export default abstract class SlotMachineFeature {
    private overrideCondition: () => boolean;

    constructor(config?: IFeatureConfig) {
        if (config?.condition) {
            this.overrideCondition = config.condition;
        }
    }

    public hasOverrideCondition(): boolean {
        return this.overrideCondition !== undefined;
    }

    public checkOverrideCondition(): boolean {
        if (!this.overrideCondition) {
            return true;
        }

        return this.overrideCondition();
    }

    public condition(): boolean {
        return this.checkOverrideCondition();
    }
    public abstract trigger(slotMachine: SlotMachineEntity): Promise<void>;
}
