import { GameBus, IGameEvents } from '../gameBus';

export interface IStateMachineConfig {
    transitions: IStateTransition[];
    initialState: string;
}

interface IStateTransition {
    from: string;
    to: string;
    events?: (keyof IGameEvents)[] | keyof IGameEvents;
}

export class StateMachine {
    private states: Set<string> = new Set();
    private transitions: IStateTransition[];
    private state: string;

    constructor(config: IStateMachineConfig) {
        config.transitions.forEach((transition) => {
            this.states.add(transition.from);
            this.states.add(transition.to);
        });

        this.state = config.initialState;
        this.transitions = config.transitions;
    }

    public enter(state: string) {
        if (this.states.has(state) && this.state !== state) {
            const currentTransition = this.transitions.find(
                (transition) => transition.from === this.state && transition.to === state,
            );

            if (!currentTransition) {
                console.error(`No transition from ${this.state} to ${state}`);
            }

            console.debug(`${this.state} => ${state}`);

            if (currentTransition && currentTransition.events) {
                if (currentTransition.events instanceof Array) {
                    currentTransition.events.forEach((event) => GameBus.emit(event as any));
                } else {
                    GameBus.emit(currentTransition.events as any);
                }
            }
            this.state = state;
        }
    }
}
