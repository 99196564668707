import SlotMachineFeature, { IFeatureConfig } from '../slotMachineFeature';

export interface IMetaFeatureConfig extends IFeatureConfig {
    features: SlotMachineFeature[];
}

export default abstract class MetaFeature extends SlotMachineFeature {
    protected features: SlotMachineFeature[];

    constructor(config: IMetaFeatureConfig) {
        super(config);
        this.features = config.features;
    }
}
