export interface IInitMessage {
    phaseConfigs: Map<string, IPhaseConfig>;
}

interface IPhaseConfig {
    paylines: IPayline;
    prizeTable: any;
    taxonomy: number[];
}

export interface IPayline {
    id: string;
    positions: ICoord[];
}

export interface ICoord {
    reel: number;
    row: number;
}

export interface IWinMessage {
    winnings: IWinnings[];
    winningsAmount: number;
    type: string;
}

export interface ITransition {
    from: ICoord;
    to: ICoord;
}

export interface IOverride {
    inCoord: ICoord;
    new: string;
    original: string;
}

export interface IWinnings {
    winningType: WINNING_TYPE;
    prizeType: PRIZE_TYPE;
    winnings: number;
    mainSymbol: string;
    occurrences: number;
    id: string;
    symbols: string[];
    coords: ICoord[];
    winningSymbols: string[];
    winningCoords: ICoord[];
    multiWin: number;
}

export interface IReel {
    symbols: Array<string>;
}

export interface IFreeSpinState {
    bet: number;
    freeSpinRemaining: number;
    freeSpinsAccumulatedWinnings: number;
    freeSpinsConsumed: number;
    lastMultiplier: number;
    nextMultiplier: number;
    panel: IPanel;
    symbolsInPanelsStopCondition: Array<null>;
    winnings: IWinnings;
}

export interface IFreeSpinStateName {
    [key: string]: IFreeSpinState;
}

export interface ICascadeSpinStateName {
    [key: string]: ICascadeState;
}

export interface ICascadeState {
    bet: number;
    cascadeLevel: number;
    cascadesAccumulatedWinnings: number;
    lastMultiplier: number;
    nextMultiplier: number;
    winnings: IWinnings;
}

export interface IPanel {
    reels: Array<IReel>;
}

export enum PRIZE_TYPE {
    MONEY = 'MONEY',
}

export enum WINNING_TYPE {
    LINE = 'LINE',
    WAY = 'WAY',
    SCATTER = 'SCATTER',
}
