interface IPaylinRenderConfig {
    animationName: string;
    yOffset?: number;
    hInverted?: boolean;
    vInverted?: boolean;
}

export const lineMap: IPaylinRenderConfig[] = [
    // 1
    { animationName: '00000', yOffset: 0 },
    // 2
    { animationName: '00000', yOffset: -275 },
    // 3
    { animationName: '00000', yOffset: 265 },
    // 4
    { animationName: '01210', yOffset: 0 },
    // 5
    { animationName: '01210', yOffset: -70, vInverted: true },
    // 6
    { animationName: '10001', yOffset: 0 },
    // 7
    { animationName: '10001', yOffset: -70, vInverted: true },
    // 8
    { animationName: '00122', yOffset: 0 },
    // 9
    { animationName: '00122', yOffset: -70, vInverted: true },
    // 10
    { animationName: '12101', yOffset: 0 },

    // 11
    { animationName: '02020', yOffset: -70, vInverted: true },
    // 12
    { animationName: '01010', yOffset: 0 },
    // 13
    { animationName: '01010', yOffset: -70, vInverted: true },
    // 14
    { animationName: '10001', yOffset: -335, vInverted: true },
    // 15
    { animationName: '10001', yOffset: 275 },
    // 16
    { animationName: '11011', yOffset: 0 },
    // 17
    { animationName: '11011', yOffset: -70, vInverted: true },
    // 18
    { animationName: '02120', yOffset: -70, vInverted: true },
    // 19
    { animationName: '02120', yOffset: 0 },
    // 20
    { animationName: '02020', yOffset: 0 },
];
