import IParser from 'game_libs/parsing/IParser';
import { ISymbolOverrideEvent } from 'game_libs/engineEvents/symbolOverrideEvent';
import DataStore from 'game_libs/dataStore';
import { CUSTOM_DATA_TYPES } from './customDataTypes';

const CUSTOM_WILD_PLACEMENT_PARSER: IParser = {
    parse: (data: ISymbolOverrideEvent) => {
        console.log(data);
        const placedWilds = data.overrides.filter((override) => override.new !== override.original);

        DataStore.set(CUSTOM_DATA_TYPES.PLACED_WILDS, placedWilds);
    },
};

export default CUSTOM_WILD_PLACEMENT_PARSER;
