import DataStore from 'game_libs/dataStore';
import DataTypes from 'game_libs/dataTypes';
import { Layer } from 'game_libs/layers/layerTypes/layer';
import { Orientation } from '../../config/layout';
import { BIG_WIN_LAYER } from './bigWinLayer';
import { BACKGROUND_LAYER } from './decoration/backgroundLayer';
import { OVERLAY_LAYER } from './overlayLayer';
import { POPUP_LAYER } from './popupLayer';
import { SLOT_MACHINE_LAYER } from './slotMachineLayer';
import { GameBus } from 'game_libs/gameBus';
import { LOGO_LAYER } from './logoLayer';
import { theme } from 'game_libs/controllers/themeController';
import { features } from 'game_libs/controllers/featureController';

export class MainLayer extends Layer {
    private overlayContainer: Layer;
    private dialogContainer: Layer;
    private backgroundContainer: Layer;
    private bigWinContainer: Layer;

    constructor() {
        super();

        this.backgroundContainer = new Layer();

        this.overlayContainer = OVERLAY_LAYER();
        this.dialogContainer = new Layer();
        this.dialogContainer.setID('dialogContainer');
        this.dialogContainer.name = 'dialogContainer';

        this.bigWinContainer = new Layer();
        this.bigWinContainer.setID('bigWinContainer');
        this.bigWinContainer.name = 'bigWinContainer';

        this.backgroundContainer.addChildLayer(BACKGROUND_LAYER());

        this.dialogContainer.addChildLayer(POPUP_LAYER());

        this.bigWinContainer.addChildLayer(BIG_WIN_LAYER());

        this.backgroundContainer.setLayoutConfigs([
            {
                width: '0%',
                height: '0%',
            },
        ]);

        this.dialogContainer.setLayoutConfigs([
            {
                width: '100%',
                height: '100%',
            },
        ]);

        this.bigWinContainer.setLayoutConfigs([
            {
                width: '100%',
                height: '100%',
            },
        ]);

        this.setLayoutConfigs([
            {
                width: '100%',
                height: '100%',
            },
        ]);

        this.addChildLayer(this.backgroundContainer);

        this.backgroundContainer.addChildLayer(SLOT_MACHINE_LAYER());
        if (features.logoSeparated) {
            this.backgroundContainer.addChildLayer(LOGO_LAYER());
        }

        this.addChildLayer(this.overlayContainer);
        this.addChildLayer(this.dialogContainer);
        this.addChildLayer(this.bigWinContainer);

        DataStore.set(DataTypes.ROOT_LAYER, this);

        GameBus.emit('screenLoaded');

/////////////////////
//////////////////////////////////////////////////
//////////////////
    }

    public visualUpdate(): void {
        super.visualUpdate();
        this.x = window.innerWidth / 2;
        this.y = window.innerHeight / 2;

        if (DataStore.get(DataTypes.ORIENTATION) === Orientation.LANDSCAPE) {
            this.landscapeScaling();
        } else {
            this.portraitScaling();
        }
    }

    private landscapeScaling() {
        const BG_SCALE = window.innerHeight / theme.bgScaling.h;
        const DIALOG_SCALE = window.innerHeight / 1024;
        const BIG_WIN_SCALE = window.innerHeight / 2048;

        this.overlayContainer.position.set(-window.innerWidth / 2, -window.innerHeight / 2);
        this.backgroundContainer.scale.set(BG_SCALE, BG_SCALE);
        this.backgroundContainer.position.set(0, 0);

        this.dialogContainer.scale.set(DIALOG_SCALE, DIALOG_SCALE);
        this.dialogContainer.position.set(0, -10);

        this.bigWinContainer.scale.set(BIG_WIN_SCALE, BIG_WIN_SCALE);
        this.bigWinContainer.position.set(0, -10);
    }

    private portraitScaling() {
        const BG_SCALE = window.innerWidth / theme.bgScaling.w;
        const DIALOG_HORIZONTAL_SCALE = window.innerWidth / 800;
        const DIALOG_VERTICAL_SCALE = window.innerHeight / 1400;
        const BIG_WIN_SCALE = window.innerWidth / 1300;
        const DIALOG_SCALE =
            DIALOG_HORIZONTAL_SCALE < DIALOG_VERTICAL_SCALE
                ? DIALOG_HORIZONTAL_SCALE
                : DIALOG_VERTICAL_SCALE;

        this.overlayContainer.position.set(-window.innerWidth / 2, -window.innerHeight / 2);
        this.backgroundContainer.scale.set(BG_SCALE, BG_SCALE);
        this.backgroundContainer.position.set(0, 0);

        this.dialogContainer.scale.set(DIALOG_SCALE, DIALOG_SCALE);
        this.dialogContainer.position.set(0, -50);

        this.bigWinContainer.scale.set(BIG_WIN_SCALE, BIG_WIN_SCALE);
        this.bigWinContainer.position.set(0, -50);
    }

    public getLayoutHeight(): number {
        return window.innerHeight;
    }

    public getLayoutWidth(): number {
        return window.innerWidth;
    }
}
