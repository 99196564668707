import { Layer } from '../layers/layerTypes/layer';
import { IWinnings } from '../platformInterface';
import SlotMachineEntity from '../slotMachine/slotMachineEntity';
import { timeout } from '../utils/timeout';
import { Controller } from './controller';
import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import SlotDataTypes from '../parsing/dataTypes/slotDataTypes';

export enum SYMBOL_CONTROLLER_ACTIONS {
    ANIMATE_ALL_WINNING_SYMBOLS = 'ANIMATE_ALL_WINNING_SYMBOLS',
    HIDE_ANIMATIONS = 'HIDE_ANIMATIONS',
    ANIMATE_WINNING_LINE = 'ANIMATE_WINNING_LINE',
}

export interface ISymbolAnimationControllerConfig {
    smSymbolBrightness?: number;
    playWinAnimation: (symbol: any, symbolID: string) => Promise<void>;
    expandingAnimation?: (spine: any, symbolID: string) => Promise<void>;
}

export class SymbolWinAnimationController extends Controller {
    config: ISymbolAnimationControllerConfig;

    constructor(config: ISymbolAnimationControllerConfig) {
        super();
        this.config = config;
    }

    public onAdd() {
        this.layer.addAction({
            name: SYMBOL_CONTROLLER_ACTIONS.ANIMATE_ALL_WINNING_SYMBOLS,
            action: this.animateAllWinningSymbols.bind(this),
        });
        this.layer.addAction({
            name: SYMBOL_CONTROLLER_ACTIONS.ANIMATE_WINNING_LINE,
            action: this.animateWinningLine.bind(this),
        });
    }

    private async animateAllWinningSymbols(
        layer: Layer,
        slotMachine: SlotMachineEntity,
        totalWin: number,
        winningData: Array<IWinnings>,
    ) {
        const animations: Promise<void>[] = [];

        winningData.forEach((winning) => {
            animations.push(this.animateWinningLine(layer, slotMachine, winning));
        });
        await Promise.all(animations);
    }

    private async animateWinningLine(
        layer: Layer,
        slotMachine: SlotMachineEntity,
        winning: IWinnings,
    ) {
        const animations: Promise<void>[] = [];

        slotMachine.deactivateAllSymbols();

        const expendedReels = await slotMachine.expandReels();

        winning.winningCoords.forEach((coord) => {
            const newPanel = DataStore.get<[[]]>(SlotDataTypes.NEW_PANEL);

            const panel = DataStore.get<[[]]>(DataTypes.PANEL);

            const symbolID = newPanel
                ? newPanel[coord.reel][coord.row]
                : panel[coord.reel][coord.row];
            const symbol = slotMachine.getSymbol(coord.reel, coord.row);

            if (
                symbol &&
                expendedReels.length &&
                this.config.expandingAnimation !== undefined &&
                expendedReels.includes(slotMachine.getReel(coord.reel)) &&
                symbolID === 'WI_EX'
            ) {
                animations.push(this.config.expandingAnimation(symbol, symbolID));
            }

            if (symbol) {
                animations.push(this.config.playWinAnimation(symbol, symbolID));
            }
        });
        await timeout(16);
        await Promise.all(animations);
    }
}
