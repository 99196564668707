import DataStore from '../dataStore';
import { ISymbolOverrideEvent } from '../engineEvents/symbolOverrideEvent';
import IParser from './IParser';
import SlotDataTypes from './dataTypes/slotDataTypes';

enum OVERRIDE_COMPONENTS {
    SymbolReplacementPanelOverrider = 'SymbolReplacementPanelOverrider',
}

let reelOffset = 0;

interface ISymbolOverrideParserConfiguration {
    reelOffset: number;
}

const SYMBOL_REPLACEMENT_PARSER: IParser = {
    configure: (configuration: ISymbolOverrideParserConfiguration) => {
        reelOffset = configuration.reelOffset;
    },

    parse: (data: ISymbolOverrideEvent) => {
        if (data.componentName === OVERRIDE_COMPONENTS.SymbolReplacementPanelOverrider) {
            DataStore.set(SlotDataTypes.OVERRIDE_SYMBOLS, data.overrides);

            const newPanel: string[][] = [];

            for (let i = reelOffset; i < data.newPanel.reels.length; i++) {
                const symbols: string[] = [];
                const reel = data.newPanel.reels[i];

                for (const symbol of reel.symbols) {
                    symbols.push(symbol);
                }

                newPanel.push(symbols);
            }

            DataStore.set(SlotDataTypes.NEW_PANEL, newPanel);
        }
    },
};

export default SYMBOL_REPLACEMENT_PARSER;
