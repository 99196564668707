import DataStore from 'game_libs/dataStore';
import DataTypes from 'game_libs/dataTypes';
import { GameBus } from 'game_libs/gameBus';
import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { SLOT_MACHINE_STATES, SlotMachineFactory } from 'game_libs/slotMachine/slotMachineFactory';
import { Sprite } from '@pixi/sprite';
import { symbolIDs } from '../../config/symbolIDs';
import { FSTransitionController } from 'controllers/fsTransitionController';

import IdleFeature from 'game_libs/slotMachine/features/idleFeature';
import AnySequenceFeature from 'game_libs/slotMachine/features/metaFeatures/anySequenceFeature';
import OrFeature from 'game_libs/slotMachine/features/metaFeatures/orFeature';
import FreeSpinDataTypes from 'game_libs/freeSpinDataTypes';

import CascadeDataTypes from 'game_libs/parsing/dataTypes/cascadeDataTypes';
import SlotDataTypes from 'game_libs/parsing/dataTypes/slotDataTypes';
import SpinFeature from 'game_libs/slotMachine/features/spinFeature';
import StartFeature from 'game_libs/slotMachine/features/startFeature';
import StopFeature from 'game_libs/slotMachine/features/stopFeature';
import SingleWinLineLoopFeature from 'game_libs/slotMachine/features/wins/singleWinLineLoopFeature';
import TotalWinFeature from 'game_libs/slotMachine/features/wins/totalWinFeature';
import { Audio } from 'game_libs/utils/audio';
import { timeout } from 'game_libs/utils/timeout';

import BigWinFeature from 'game_libs/slotMachine/features/wins/bigWinFeature';
import { BIG_WIN_CONFIG } from '../../config/bigWinConfig';
import { BONUS_SYMBOL_ANIMATION_ACTIONS } from '../controllers/bonusSymbolAnimation';
import PlaceWildsFeature from '../features/placeWildsFeature';
import RetriggerPopupFeature from '../features/retriggerPopupFeature';
import { theme } from 'game_libs/controllers/themeController';
import { features } from 'game_libs/controllers/featureController';

import { TOTAL_WIN_LAYER } from './totalWinLayer';
import { ANTICIPATION_LAYER } from './anticipationLayer';
import { BONUS_SYMBOL_ANIMATION_LAYER } from './bonusSymbolLayer';
import { PLACED_WILDS_LAYER } from './placedWildsLayer';
import { PAY_LINES_LAYER } from './paylinesLayer';
import { SymbolWinAnimationController } from 'game_libs/controllers/symbolWinAnimationController';
import { SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';
import SequenceFeature from 'game_libs/slotMachine/features/sequenceFeature';
import { Texture } from '@pixi/core';
import { game } from '../../main';

let scatterCount = 0;
let reelCount = 0;

export const SLOT_MACHINE_LAYER = () => {
    let expanded: SpineLayer;

    const blurredTextures: Map<string, Texture> = new Map();

    symbolIDs.forEach((symbolID) => {
        const symbol = LayerFactory.CreateSpine({ spineData: symbolID });

        symbol.play('blur');

        blurredTextures.set(symbolID, game.pixiApp.renderer.generateTexture(symbol));

        symbol.destroy();
    });

    const slotMachineLayer = LayerFactory.Create(LayerType.CONTAINER, {
        id: 'slotMachine',
        controllers: [
            new FSTransitionController(),
            new SymbolWinAnimationController({
                expandingAnimation: async (spine: SpineLayer) => {
                    if (!spine.visible) return;

                    let hasExpanding = false;

                    spine.getSpine().state.data.skeletonData.animations.forEach((animation) => {
                        if (animation.name === 'expanding') {
                            hasExpanding = true;
                        }
                    });

                    const state: any = spine.getSpine().state;
                    const currentAnimation = state.getCurrent(0)?.animation?.name;
                    const isPlaying = currentAnimation !== 'static';

                    if (hasExpanding && !isPlaying) {
                        await spine.play('expanding');

                        if (
                            spine instanceof SpineLayer &&
                            spine
                                ?.getSpine()
                                ?.spineData?.animations.find(
                                    (animation) => animation.name === 'active',
                                )
                        ) {
                            spine.play('active', true);
                        }
                    }
                },
                playWinAnimation: async (spine: SpineLayer, symbolID: string) => {
                    if (symbolID !== 'WI_EX') {
                        spine.play('active');

                        await timeout(2000);
                    }
                },
            }),
        ],
        layout: theme.slotMachineLayer,
    });

    const sm = SlotMachineFactory.createSlotMachine({
        symbolWidth: theme.symbolSize.w,
        symbolHeight: theme.symbolSize.h,
        createMask: false,
        chunkSize: 5,
        spinSpeed: 5,
        reelSpacing: theme.reelSpacing,
        features: new Map([
            [
                SLOT_MACHINE_STATES.IDLE,
                new OrFeature({
                    features: [
                        // free spins flow
                        // only when free spins active and there is a next free spin
                        new AnySequenceFeature({
                            features: [
                                new PlaceWildsFeature(),

                                new OrFeature({
                                    features: [
                                        new SequenceFeature({
                                            features: [
                                                new TotalWinFeature({ showTotalWin: false }),
                                                new BigWinFeature(BIG_WIN_CONFIG),
                                            ],
                                        }),
                                        new TotalWinFeature({ showPaylines: true }),
                                    ],
                                }),

                                new RetriggerPopupFeature(),

                                new SingleWinLineLoopFeature({
                                    time: 700,
                                    showPaylines: true,
                                    slotMachineEnd: true,
                                    condition: () =>
                                        DataStore.get<number>(DataTypes.TOTAL_WIN) > 0 &&
                                        !DataStore.get(FreeSpinDataTypes.IS_NEXT),
                                }),

                                new IdleFeature(),
                            ],
                            condition: () =>
                                DataStore.get(FreeSpinDataTypes.IS_ACTIVE) ||
                                DataStore.get(FreeSpinDataTypes.IS_NEXT),
                        }),

                        // main flow
                        // when win is > 0
                        new AnySequenceFeature({
                            features: [
                                new PlaceWildsFeature(),

                                new OrFeature({
                                    features: [
                                        new SequenceFeature({
                                            features: [
                                                new TotalWinFeature({ showTotalWin: false }),
                                                new BigWinFeature(BIG_WIN_CONFIG),
                                            ],
                                        }),
                                        new TotalWinFeature({ showPaylines: true }),
                                    ],
                                }),

                                new SingleWinLineLoopFeature({
                                    time: 700,
                                    showPaylines: true,
                                }),
                            ],
                            condition: () => DataStore.get<number>(DataTypes.TOTAL_WIN) > 0,
                        }),

                        new IdleFeature(),
                    ],
                }),
            ],
            [
                SLOT_MACHINE_STATES.STARTING,
                new OrFeature({
                    features: [
                        new StartFeature({
                            reelsStartDelay: [0, 0, 0, 0, 0],
                            startTime: 100,
                            condition: () => {
                                const cascading = DataStore.get<boolean>(SlotDataTypes.CASCADING);

                                return !cascading;
                            },
                        }),
                    ],
                }),
            ],
            [
                SLOT_MACHINE_STATES.SPINNING,
                new AnySequenceFeature({
                    features: [
                        new SpinFeature({
                            time: 1000,
                            condition: () => !DataStore.get<boolean>(SlotDataTypes.CASCADING),
                        }),
                    ],
                }),
            ],
            [
                SLOT_MACHINE_STATES.STOPPING,
                new AnySequenceFeature({
                    features: [
                        new StopFeature({
                            anticipationTime: 2000,
                            stopTime: 330,
                            reelsStopDelay: [0, 300, 600, 900, 1200],
                            condition: () => !DataStore.get<boolean>(SlotDataTypes.CASCADING),
                            anticipationRule: (reel, index, slotMachine) => {
                                if (DataStore.get<boolean>(FreeSpinDataTypes.IS_ACTIVE)) {
                                    return false;
                                }
                                if (index > 1) {
                                    let symbolCount = 0;

                                    for (let i = 0; i < index; i++) {
                                        const reel = slotMachine.getReel(i);

                                        symbolCount += reel.countSymbols('SC') >= 1 ? 1 : 0;
                                    }

                                    return symbolCount > 1;
                                }

                                return false;
                            },
                        }),
                    ],
                }),
            ],
        ]),
        symbolFactory: (spineData: string, finalPanel: boolean) => {
            if (!finalPanel) {
                const sprite = Sprite.from(blurredTextures.get(spineData));

                sprite.anchor.set(0.5);
                sprite.scale.set(theme.symbolScaling ?? 1);

                return sprite;
            }

            const spine = LayerFactory.CreateSpine({ spineData });

            spine.scale.set(theme.symbolScaling ?? 1);

            if (expanded !== spine) {
                spine.play(finalPanel ? 'static' : 'blur');
            }

            return spine;
        },
        symbolIDs,
    });

/////////////////
///////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////
///////
//////////////

    sm.setSymbolIDs(symbolIDs);
    sm.setPanel(DataStore.get(DataTypes.PANEL));

    slotMachineLayer.addChild(sm);

    slotMachineLayer.addChildLayer(ANTICIPATION_LAYER());

    const { position, scale, texture, size } = theme.slotMachineLayerMask;

    const mask = Sprite.from(texture);

    mask.visible = false;
    mask.position.set(position.x, position.y);

    if (scale) {
        mask.scale.set(scale.x, scale.y);
    }

    if (size) {
        mask.width = size.w;
        mask.height = size.h;
    }

/////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
/////////

////////////////////////////////////////
/////////////////////////////////////////////////////
//////////////////////////////////////////////////////
/////////

/////////////////////////////////////////////////////
////////////////////////////////
////////////////
///////////////////////////
/////////
///////
//////////////

    slotMachineLayer.addChild(mask);

    sm.position.set(10, 40);

    if (features.bonusSymbol) {
        slotMachineLayer.addChildLayer(BONUS_SYMBOL_ANIMATION_LAYER());
    }

    if (features.placedWilds) {
        slotMachineLayer.addChildLayer(PLACED_WILDS_LAYER());
    }

    if (features.payLines) {
        slotMachineLayer.addChildLayer(PAY_LINES_LAYER());
    }

    slotMachineLayer.addChildLayer(TOTAL_WIN_LAYER());

    slotMachineLayer.visualUpdate();

    GameBus.on('reelStopping', async (reelSymbols) => {
        Audio.play('Reel Stop', false, 0.8);

        if (reelSymbols.includes('SC')) {
            scatterCount++;
            Audio.play(`scatter${scatterCount}`);

            await timeout(600);

            const bonusSymbolLayer = slotMachineLayer.findRecursive('bonusSymbolLayer');

            bonusSymbolLayer?.do(
                BONUS_SYMBOL_ANIMATION_ACTIONS.ANIMATE_BONUS_ANIMATION,
                sm,
                reelCount,
                3 - reelSymbols.indexOf('SC'),
            );
        }

        // sm.getReel(reelCount).activateAllSymbols();

        reelCount++;
    });

    GameBus.on('spinStart', async () => {
        sm.mask = mask;
        mask.visible = true;

        expanded = null;
        scatterCount = 0;
        reelCount = 0;
        DataStore.set(CascadeDataTypes.ACCUMULATED_WINNINGS, 0);
        Audio.play('Reel Start', false, 0.45);
        Audio.play('Reel Spin', true, 0.2);
        sm.start().catch((error) => {
            console.error(error);
        });

        await timeout(600);

        // sm.blurAllSymbols();
    });

    GameBus.on('spinStop', async () => {
        sm.mask = null;
        mask.visible = false;

        Audio.stop('Reel Spin');

        await timeout(600);

        const bonusSymbolLayer = slotMachineLayer.findRecursive('bonusSymbolLayer');

        bonusSymbolLayer?.do(BONUS_SYMBOL_ANIMATION_ACTIONS.HIDE_ANIMATIONS, sm);
    });

    GameBus.on('screenLoaded', () => sm.initialise());

    GameBus.on('resumeCascades', () =>
        sm.playStateFeatures('IDLE').catch((error) => {
            console.error(error);
        }),
    );

    return slotMachineLayer;
};
