export namespace Spline {
    export function process(
        points: Array<number>,
        tension = 0.5,
        resolution = 200,
        closed = false,
    ): Float32Array {
        tension = 1 - tension;
        let pts: Array<number>;
        let i = 1;
        let l = points.length;
        let rPos = 0;
        const rLen = (l - 2) * resolution + 2 + (closed ? 2 * resolution : 0);
        const res = new Float32Array(rLen);
        const cache = new Float32Array((resolution + 2) * 4);
        let cachePtr = 4;

        pts = points.slice(0);

        if (closed) {
            pts.unshift(points[l - 1]);
            pts.unshift(points[l - 2]);
            pts.push(points[0], points[1]);
        } else {
            pts.unshift(points[1]);
            pts.unshift(points[0]);
            pts.push(points[l - 2], points[l - 1]);
        }

        cache[0] = 1; // 1,0,0,0

        for (; i < resolution; i++) {
            const st = i / resolution;
            const st2 = st * st;
            const st3 = st2 * st;
            const st23 = st3 * 2;
            const st32 = st2 * 3;

            cache[cachePtr++] = st23 - st32 + 1; // c1
            cache[cachePtr++] = st32 - st23; // c2
            cache[cachePtr++] = st3 - 2 * st2 + st; // c3
            cache[cachePtr++] = st3 - st2; // c4
        }

        cache[++cachePtr] = 1; // 0,1,0,0

        // calc. points
        parse(pts, cache, l);

        if (closed) {
            pts = [];
            pts.push(points[l - 4], points[l - 3], points[l - 2], points[l - 1]); // second last and last
            pts.push(points[0], points[1], points[2], points[3]); // first and second
            parse(pts, cache, 4);
        }

        function parse(pts: number[], cache: Float32Array, l: number) {
            for (let i = 2, t; i < l; i += 2) {
                const pt1 = pts[i];
                const pt2 = pts[i + 1];
                const pt3 = pts[i + 2];
                const pt4 = pts[i + 3];
                const t1x = (pt3 - pts[i - 2]) * tension;
                const t1y = (pt4 - pts[i - 1]) * tension;
                const t2x = (pts[i + 4] - pt1) * tension;
                const t2y = (pts[i + 5] - pt2) * tension;

                for (t = 0; t < resolution; t++) {
                    const c = t << 2; // t * 4;
                    const c1 = cache[c];
                    const c2 = cache[c + 1];
                    const c3 = cache[c + 2];
                    const c4 = cache[c + 3];

                    res[rPos++] = c1 * pt1 + c2 * pt3 + c3 * t1x + c4 * t2x;
                    res[rPos++] = c1 * pt2 + c2 * pt4 + c3 * t1y + c4 * t2y;
                }
            }
        }

        l = closed ? 0 : points.length - 2;
        res[rPos++] = points[l];
        res[rPos] = points[l + 1];

        return res;
    }
}
