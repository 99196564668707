import { Controller } from 'game_libs/controllers/controller';
import { GameBus } from 'game_libs/gameBus';
import DataTypes from 'game_libs/dataTypes';
import DataStore from 'game_libs/dataStore';
import { timeout } from 'game_libs/utils/timeout';
import FreeSpinDataTypes from 'game_libs/freeSpinDataTypes';
import { AwaitReply } from 'game_libs/utils/awaitReply';
import SlotDataTypes from 'game_libs/parsing/dataTypes/slotDataTypes';
import { Audio } from 'game_libs/utils/audio';

declare const EventManager: any;

declare const MagicUI: any;

export class FSTransitionController extends Controller {
    public onAdd(): void {
        GameBus.on('resumeFreeSpins', async () => {
            MagicUI.startFreeSpins();
            MagicUI.showFreeSpinBar();
            EventManager.emit('disable-ui', {});
            const consumedFS = DataStore.get<number>(FreeSpinDataTypes.CONSUMED);
            const remainingFS = DataStore.get<number>(FreeSpinDataTypes.REMAINING);

            MagicUI.updateFreeSpins(consumedFS + 1, consumedFS + remainingFS);
            MagicUI.updateFreeSpinWinnings(DataStore.get(FreeSpinDataTypes.TOTAL_WIN));

            await AwaitReply('showFreeSpinEnterDialog', 'freeSpinStartClick');
            await timeout(500);
            if (DataStore.get(SlotDataTypes.CASCADING)) {
                GameBus.emit('resumeCascades');
            } else {
                EventManager.emit('magic-ui:action', {
                    bet: DataStore.get(DataTypes.TOTAL_BET),
                    isFreeSpin: true,
                });
            }
        });

        GameBus.on('enterFreeSpins', async () => {
            Audio.play('Free Spins', true, 0.35);
            Audio.stop('Main');
            MagicUI.startFreeSpins();
            EventManager.emit('disable-ui', {});

            await AwaitReply('showFreeSpinEnterDialog', 'freeSpinStartClick');
            MagicUI.showFreeSpinBar();
            const consumedFS = DataStore.get<number>(FreeSpinDataTypes.CONSUMED);
            const remainingFS = DataStore.get<number>(FreeSpinDataTypes.REMAINING);

            MagicUI.updateFreeSpins(consumedFS + 1, consumedFS + remainingFS);
            MagicUI.updateFreeSpinWinnings(DataStore.get(FreeSpinDataTypes.TOTAL_WIN));
            await timeout(500);
            EventManager.emit('disable-ui', {});
            EventManager.emit('magic-ui:action', {
                bet: DataStore.get(DataTypes.TOTAL_BET),
                isFreeSpin: true,
            });
        });

        GameBus.on('exitFreeSpins', async () => {
            Audio.play('Main', true, 0.35);
            Audio.stop('Free Spins');
            MagicUI.endFreeSpins();
            EventManager.emit('disable-ui', {});
            await AwaitReply('showFreeSpinExitDialog', 'freeSpinExitClick');
            EventManager.emit('game:winnings', {
                amount: DataStore.get(FreeSpinDataTypes.TOTAL_WIN),
            });
            MagicUI.closeFreeSpinBar();
        });
    }
}
