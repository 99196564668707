import { Layer } from './layer';
import { Emitter, EmitterConfigV3 } from '@pixi/particle-emitter';
import { Ticker } from '@pixi/core';

export class ParticleEmitterLayer extends Layer {
    private emitter: Emitter;
    private ticker: Ticker;

    constructor() {
        super();
        this.ticker = Ticker.shared;
        this.ticker.add(this.update, this);
    }

    public setConfig(config: EmitterConfigV3) {
        this.emitter = new Emitter(this, config);
        this.emitter.emit = false;
    }

    public getEmitter(): Emitter {
        return this.emitter;
    }

    public play() {
        this.ticker.start();
        this.emitter.emit = true;
    }

    public stop() {
        this.emitter.emit = false;
    }

    private update() {
        this.emitter.update(this.ticker.elapsedMS * 0.001);
    }
}
