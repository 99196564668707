import { Controller } from 'game_libs/controllers/controller';
import { PAYLINES_CONTROLLER_ACTIONS } from 'game_libs/controllers/paylinesController';
import { GameBus } from 'game_libs/gameBus';

import { Layer } from 'game_libs/layers/layerTypes/layer';

export interface ICustomPaylinesControllerConfig {
    paylineFactory: () => Layer;
    playPaylineAnimation: (payline: Layer, paylineIndex: number) => Promise<void>;
}

export class CustomPaylinesController extends Controller {
    protected paylines: Layer[] = [];
    protected config: ICustomPaylinesControllerConfig;

    constructor(config: ICustomPaylinesControllerConfig) {
        super();
        this.config = config;
    }

    public override onAdd(): void {
        for (let i = 0; i < 20; i++) {
            this.createPayline();
        }

        GameBus.on('keydown', (code) => {
            if (code.startsWith('Digit')) {
                this.showPayline(parseInt(code.substring(5), 10));
            }
        });
    }

    public override do(layer: Layer, action: string, paylineIndex: number): Promise<void> {
        return new Promise((resolve) => {
            switch (action) {
                case PAYLINES_CONTROLLER_ACTIONS.SHOW_PAYLINE:
                    this.showPayline(paylineIndex - 1);
                    break;
                case PAYLINES_CONTROLLER_ACTIONS.HIDE_ALL_PAYLINES:
                    this.hideAllPaylines();
                    break;
                case PAYLINES_CONTROLLER_ACTIONS.HIDE_PAYLINE:
                    this.hidePayline(paylineIndex - 1);
                    break;
            }
            resolve();
        });
    }

    protected createPayline() {
        const payline = this.config.paylineFactory();

        this.paylines.push(payline);
        this.layer.addChildLayer(payline);
        payline.visible = false;
    }

    protected showPayline(paylineIndex: number) {
        if (!this.paylines[paylineIndex]) return;

        this.paylines[paylineIndex].visible = true;
        this.config.playPaylineAnimation(this.paylines[paylineIndex], paylineIndex);
    }

    protected hidePayline(paylineIndex: number) {
        this.paylines[paylineIndex].visible = false;
    }

    protected hideAllPaylines() {
        this.paylines.forEach((payline, index) => this.hidePayline(index));
    }
}
