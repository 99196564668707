import { Layer } from './layer';
import { Texture } from '@pixi/core';

import DataStore from '../../dataStore';
import DataTypes from '../../dataTypes';
import { AnimatedSprite, FrameObject } from '@pixi/sprite-animated';

export class SpriteAnimationLayer extends Layer {
    private animatedSprite: AnimatedSprite;

    public getAnimatedSprite(): AnimatedSprite {
        return this.animatedSprite;
    }

    public setTextures(texturePaths: string[]) {
        // this.sprite.texture = ;
        const textures: FrameObject[] = [];

        texturePaths.forEach((texturePath) => {
            textures.push({
                texture: DataStore.get<Record<string, Texture>>(DataTypes.TEXTURES)[texturePath],
                time: 100,
            });
        });
        this.animatedSprite = new AnimatedSprite(textures);
        this.addChild(this.animatedSprite);

        this.visualUpdate();
    }

    public setSpriteAnchor(x: number, y: number) {
        this.animatedSprite.anchor.set(x, y);
    }

    public play() {
        this.animatedSprite.play();
    }

    public stop() {
        this.animatedSprite.stop();
    }
}
