import SlotMachineEntity from '../../slotMachineEntity';
import MetaFeature from './metaFeature';

/**
 * AnySequenceFeature
 * all features work if meet condition one after another
 * * if one of the features fails, sequence continues
 */
export default class AnySequenceFeature extends MetaFeature {
    public condition(): boolean {
        for (const feature of this.features) {
            if (feature.condition()) {
                return true;
            }
        }

        return false;
    }

    public async trigger(slotMachine: SlotMachineEntity): Promise<void> {
        for (const feature of this.features) {
            console.log(`Evaluating ${feature.constructor.name}`);
            if (feature.hasOverrideCondition()) {
                if (feature.checkOverrideCondition()) {
                    await feature.trigger(slotMachine);
                } else {
                    console.log(`${feature.constructor.name} override condition failed`);
                }
            } else if (feature.condition()) {
                await feature.trigger(slotMachine);
            } else {
                console.log(`${feature.constructor.name} condition failed`);
            }
        }
    }
}
